import classNames from 'classnames'
import styles from './index.module.css'

const RadioButton = ({
  color,
  type,
  label = '',
  id,
  checked,
  onChange,
}) => (
  <label className={classNames(styles.item, checked && styles.checked, styles[type], styles[color])}>
    <div className={styles.button} />
    <input
      className={styles.input}
      type="checkbox"
      name={label || id}
      checked={checked}
      onChange={onChange(id)}
    />
    {label}
  </label>
)

export default RadioButton
