import { types, getSnapshot } from 'mobx-state-tree'

import { getDeepMap } from '../helpers/types'
import { callApi, getFullUrl } from '../helpers/fetch'


let store

import('../index').then(module => {
  store = module.getStore()
})

const HttpQuery = types.model('GetQuery', {
  callTime: types.number,
  response: getDeepMap(10),
  loaded: types.boolean,
}).views(self => ({
  get(path = '', defaultValue) {
    const pathArray = path.split('.')
    const value = path ? pathArray.reduce((memo, key, i) => {
      if (typeof memo?.get === 'function') return memo.get(key)
      if ((memo || {})[key]) return memo[key]
      return undefined
    }, self.response) : self.response
    try {
      return getSnapshot(value)
    } catch {
      return value || defaultValue
    }
  },
})).actions(self => ({
  setLoaded(loaded) {
    self.loaded = loaded
  },
  setCallTime(callTime) {
    self.callTime = callTime
  },
  setResponse(response) {
    self.response = response
  },
}))

const HttpStore = types.model('store', {
  httpData: types.map(HttpQuery),
}).views(self => ({
  get token() {
    return store?.authStore?.token
  },
})).actions(self => ({
  fetchRequest(tmpURL, { params, headers, cacheTime = 5000, method = 'GET', onSuccess, ...options } = {}) {
    const url = getFullUrl(tmpURL, params)

    if (self.httpData.has(url)) {
      const prev = self.httpData.get(url)
      if (Date.now() - prev.callTime <= cacheTime) {
        if (onSuccess) {
          onSuccess(prev)
        }
        return prev
      }
    } else {
      self.httpData.set(url, {
        callTime: Date.now(),
        response: {},
        loaded: false,
      })
    }

    const httpData = self.httpData.get(url)

    httpData.setCallTime(Date.now())
    httpData.setLoaded(false)

    const { token } = self
    const myHeaders = {
      ...headers,
    }
    if (token) {
      myHeaders.Authorization = token
    }

    callApi(url, {
      ...options,
      method,
      headers: myHeaders,
    })
      .then(response => {
        httpData.setLoaded(true)
        httpData.setResponse(response)
        if (onSuccess) {
          onSuccess(httpData)
        }
      })

    return httpData
  },
}))

export default HttpStore
