/* eslint-disable max-len */
import React from 'react'

const Logout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={28}
    viewBox="0 0 26 28"
    {...props}
  >
    <path d="M25.2699 7.9911C23.1108 3.90649 18.8192 1.12305 13.8776 1.12305C6.7655 1.12305 1 6.88855 1 14.0006C1 21.1128 6.7655 26.8783 13.8776 26.8783C18.8192 26.8783 23.1108 24.0948 25.2699 20.0102" stroke="#314560" strokeWidth="1.4"/>
    <path d="M7.86914 14H23.7515" stroke="#314560" strokeWidth="1.4"/>
    <path d="M19.8867 9.70703L23.75 13.9996L19.8867 18.2921" stroke="#314560" strokeWidth="1.4"/>
  </svg>
)

export default Logout
