/* eslint-disable max-len */
import React from 'react'

const Play = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    viewBox="0 0 100 100"
    {...props}
  >
    <circle cx="50" cy="50" r="49" stroke="white" strokeWidth="2"/>
    <path d="M64.5 49.634C65.1667 50.0189 65.1667 50.9811 64.5 51.366L42.75 63.9234C42.0833 64.3083 41.25 63.8272 41.25 63.0574L41.25 37.9426C41.25 37.1728 42.0833 36.6917 42.75 37.0766L64.5 49.634Z" fill="white"/>
  </svg>
)

export default Play
