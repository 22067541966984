import { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import { nowDate } from '@/utils/helpers/date'
import { getQuestionaryStatus } from '@/utils/helpers/questionary'

import Select, { SELECT_TYPES, SELECT_LABEL_POSITION } from '@/components/Select'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'


const statuses = [
  {
    value: 'draft',
    label: 'Черновик',
  },
  {
    value: 'publish',
    label: 'Опубликовано',
  },
]

const Header = ({
  form,
}) => {
  const store = useContext(StoreContext)

  const { httpStore, formsStore } = store

  const title = form.get('data.title', '')

  const status = getQuestionaryStatus(form?.get('data.published_at'), form?.get('data.archived_at'))

  const dataEndpoint = getApiPath('operations/')
  const operations = httpStore.fetchRequest(dataEndpoint).get('data', [])
  const operation = form.get('data.operation_id', '')

  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{title}</h1>
        <Icon
          className={styles.edit}
          type={ICONS_TYPES.edit}
          size={20}
          onClick={() => {
            const formName = 'editQuestionaryName'
            formsStore.createForm(formName, {
              modalComponent: 'InputModal',
              form: {
                data: {
                  value: title,
                },
              },
              props: {
                title: 'Редактировать название анкеты',
                label: 'Название',
                placeholder: 'Введите название *',
                required: true,
                onSuccess: form.set('data.title'),
              },
            })
          }}
        />
      </div>
      <div className={styles.info}>
        <Select
          required
          type={SELECT_TYPES.tiny}
          labelPosition={SELECT_LABEL_POSITION.left}
          label="Операция"
          placeholder="Не выбрано"
          options={operations.map(item => ({ value: item.id, label: item.name }))}
          values={operation ? [operation] : []}
          error={form.get('errors.operation_id', '')}
          onChange={v => form.set('data.operation_id')(v[0])}
          onError={form.set('errors.operation_id')}
        />
        <Select
          required
          type={SELECT_TYPES.tiny}
          labelPosition={SELECT_LABEL_POSITION.left}
          label="Статус"
          placeholder="Не выбрано"
          options={statuses}
          values={[status]}
          onChange={v => {
            const s = v[0]
            let published_at
            let archived_at

            switch (s) {
              case 'draft':
                published_at = null
                archived_at = null
                break
              case 'publish':
                published_at = nowDate()
                archived_at = null
                break
              case 'archive':
                archived_at = nowDate()
                break
              default:
            }

            form.set('data.published_at')(published_at)
            form.set('data.archived_at')(archived_at)
          }}
        />
      </div>
    </div>
  )
}

export default observer(Header)
