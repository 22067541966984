import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'


import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'
import { transformQuestionsToBlocks, getResults } from '@/utils/helpers/questionary'

import { nowDate, formatDate } from '@/utils/helpers/date'
import { getQuestionaryStatus } from '@/utils/helpers/questionary'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'
import DropDownList from '@/components/DropDownList'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'
import { checkResults } from '../../utils/helpers/questionary'


const statuses = {
  draft: 'Черновик',
  publish: 'Опубликовано',
  archive: 'Архив',
}

const Questionaries = () => {
  const store = useContext(StoreContext)
  const navigate = useNavigate()

  const { httpStore, formsStore } = store

  const params = {
    limit: 1000,
    'order_by': '-id',
  }
  const surveysEndpoint = 'surveys/'
  const dataEndpoint = getApiPath(surveysEndpoint)
  const data = httpStore.fetchRequest(dataEndpoint, { params }).get('data', [])

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Управление анкетами/типами операций
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createQuestionary'
              formsStore.createForm(formName, {
                modalComponent: 'QuestionaryEditModal',
                form: {
                  data: {
                    questions: [],
                  },
                },
                props: {
                  onSuccess: ({ data }) => navigate(data.id.toString()),
                },
              })
            }}
          >
            <span>+ Создать анкету</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={data}
          head={[
            {
              title: 'Тип операции',
              className: styles.name,
            },
            {
              title: 'Операция',
              className: styles.name,
            },
            {
              title: 'Дата создания',
              className: styles.date,
            },
            {
              title: 'Статус',
              className: styles.status,
            },
            {
              className: styles.dotMenu,
            },
          ]}
          body={[
            {
              model: item => item.title,
            },
            {
              model: item => item.operation?.name,
            },
            {
              model: item => formatDate(item.created_at),
            },
            {
              model: item => {
                const status = getQuestionaryStatus(item.published_at, item.archived_at)
                return statuses[status]
              },
            },
            {
              className: styles.dotMenu,
              model: item => {
                const status = getQuestionaryStatus(item.published_at, item.archived_at)
                return (
                  <DropDownList
                    hasBackground={false}
                    hasBorder
                    sideToOpen="left"
                    HeadComponent={({ isActive }) => (
                      <Icon
                        className={isActive && styles.dotMenuActive}
                        type={ICONS_TYPES.dotMenu}
                      />
                    )}
                  >
                    {status === 'draft' && (
                      <Button
                        className={styles.edit}
                        type={BUTTON_TYPES.text}
                        link={item.id.toString()}
                      >
                        Редактировать
                      </Button>
                    )}
                    {['draft', 'publish'].includes(status) && (
                      <Button
                        className={styles.edit}
                        type={BUTTON_TYPES.text}
                        onClick={() => {
                          const url = getApiPath(surveysEndpoint + item.id)
                          formsStore.sendQuickForm(
                            {
                              form: {
                                data: {
                                  archived_at: nowDate(),
                                },
                              },
                            },
                            url,
                            'PATCH'
                          )
                            .then(() => {
                              httpStore.fetchRequest(dataEndpoint, {
                                params,
                                cacheTime: 0,
                              })
                            })
                        }}
                      >
                        Архивировать
                      </Button>
                    )}
                    {status === 'archive' && (
                      <Button
                        className={styles.edit}
                        type={BUTTON_TYPES.text}
                        onClick={() => {
                          const url = getApiPath(surveysEndpoint + item.id)
                          formsStore.sendQuickForm(
                            {
                              form: {
                                data: {
                                  archived_at: null,
                                },
                              },
                            },
                            url,
                            'PATCH'
                          )
                            .then(() => {
                              httpStore.fetchRequest(dataEndpoint, {
                                params,
                                cacheTime: 0,
                              })
                            })
                        }}
                      >
                        Разархивировать
                      </Button>
                    )}
                    {status === 'draft' && (
                      <Button
                        className={styles.edit}
                        type={BUTTON_TYPES.text}
                        onClick={() => {
                          if (!item.questions?.length) {
                            return formsStore.createForm('alertQuiz', {
                              modalComponent: 'AlertModal',
                              props: {
                                title: 'Ошибка',
                                text: 'Вы пытаетесь опубликовать пустую анкету. ' +
                                  'Проверьте заполнение анкеты и повторите попытку.',
                              },
                            })
                          }

                          const blocks = transformQuestionsToBlocks(item.questions)
                          const results = item.questions.filter(q => q.type === 'result')

                          const newResults = getResults(blocks, results)
                          const validResults = checkResults(newResults)
                          if (!validResults) {
                            return formsStore.createForm('alertResults', {
                              modalComponent: 'AlertModal',
                              props: {
                                title: 'Ошибка',
                                text: 'Вы пытаетесь опубликовать анкету, в которой заполнены не все результаты. ' +
                                  'Проверьте таблицу результатов и повторите попытку.',
                              },
                            })
                          }
                        }}
                      >
                        Опубликовать
                      </Button>
                    )}
                  </DropDownList>
                )
              },
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Questionaries)
