/* eslint-disable max-len */
import React from 'react'

const LogoMain = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={130}
    height={61}
    viewBox="0 0 130 61"
    {...props}
  >
    <g clipPath="url(#clip0_554_25478)">
      <path d="M4.69269 0.00390625H0V60.8339H4.69269V0.00390625Z" fill="#D12D38"/>
      <path d="M17.0033 11.6988L11.3025 6.22826V11.6988H9.38574V0H11.3025V5.04138L16.0118 0H18.4566L13.2359 5.60263L19.6137 11.6988H17.0033Z" fill="#314560"/>
      <path d="M21.2323 11.7484L21.5965 9.96885C21.9706 10.0349 22.29 10.0679 22.5549 10.0679C22.9406 10.0679 23.2633 9.98041 23.5216 9.80378C23.7798 9.6288 24.0248 9.31516 24.2565 8.8645L19.6797 0H21.8944L25.3639 6.73999L28.8499 0H31.015L26.2892 9.09561C25.7827 10.0844 25.2563 10.7992 24.7117 11.2383C24.1671 11.6774 23.5034 11.8969 22.7204 11.8969C22.1146 11.8969 21.6197 11.8474 21.234 11.7484H21.2323Z" fill="#314560"/>
      <path d="M40.8623 11.6988H38.9124V6.98595C37.9871 7.30455 36.9509 7.46302 35.8054 7.46302C34.5938 7.46302 33.6056 7.12792 32.8392 6.45772C32.0728 5.78751 31.6904 4.88621 31.6904 3.75545V0H33.6403V3.36092C33.6403 4.8664 34.4663 5.61914 36.1183 5.61914C37.2091 5.61914 38.1394 5.46067 38.9107 5.14207V0H40.8606V11.6988H40.8623Z" fill="#314560"/>
      <path d="M43.7363 11.6988V0H50.7911V1.84553H45.6697V4.84494H49.8012V6.69047H45.6697V9.85495H50.7911V11.7005H43.7363V11.6988Z" fill="#314560"/>
      <path d="M59.6816 1.0961C60.4414 1.82738 60.8221 2.73529 60.8221 3.82313C60.8221 4.91097 60.4414 5.82053 59.6816 6.55016C58.9218 7.28144 57.9469 7.64625 56.7568 7.64625H54.9558V11.7005H53.0225V0H56.7568C57.9469 0 58.9218 0.364815 59.6816 1.0961ZM58.3342 5.26423C58.7249 4.88456 58.9202 4.40419 58.9202 3.82313C58.9202 3.24206 58.7249 2.76005 58.3342 2.38203C57.9436 2.00235 57.4122 1.81417 56.7402 1.81417H54.9558V5.83539H56.7402C57.4122 5.83539 57.9436 5.64555 58.3342 5.26753V5.26423Z" fill="#314560"/>
      <path d="M17.0013 28.0758L11.3006 22.6052V28.0758H9.38379V16.377H11.3006V21.42L16.0098 16.377H18.4547L13.2339 21.9796L19.6117 28.0758H17.0013Z" fill="#314560"/>
      <path d="M21.2304 28.1253L21.5945 26.3458C21.9686 26.4118 22.2881 26.4449 22.5529 26.4449C22.9386 26.4449 23.2614 26.3574 23.5196 26.1807C23.7778 26.0058 24.0228 25.6921 24.2546 25.2415L19.6777 16.377H21.8925L25.3619 23.1169L28.8479 16.377H31.013L26.2872 25.4726C25.7807 26.4614 25.2543 27.1761 24.7098 27.6152C24.1652 28.0543 23.5014 28.2739 22.7185 28.2739C22.1126 28.2739 21.6177 28.2244 21.232 28.1253H21.2304Z" fill="#314560"/>
      <path d="M32.5986 24.3682V16.377H40.9925V28.0758H39.0426V18.2225H34.5485V24.6158C34.5485 25.8456 34.287 26.7634 33.764 27.3676C33.2409 27.9718 32.5391 28.2739 31.6568 28.2739C31.2264 28.2739 30.8143 28.2244 30.417 28.1253L30.748 26.4118C30.9235 26.4564 31.1387 26.4779 31.3919 26.4779C31.8223 26.4779 32.1302 26.3276 32.3172 26.0239C32.5043 25.7218 32.5986 25.1705 32.5986 24.3682Z" fill="#314560"/>
      <path d="M43.8682 28.0758V16.377H50.9229V18.2225H45.8015V21.2219H49.9331V23.0674H45.8015V26.2319H50.9229V28.0774H43.8682V28.0758Z" fill="#314560"/>
      <path d="M53.1523 28.0758V16.377H55.1023V26.2303H58.8697V16.377H60.803V26.2303H64.5704V16.377H66.5203V28.0758H53.154H53.1523Z" fill="#314560"/>
      <path d="M79.011 26.5101C77.8325 27.6854 76.4007 28.273 74.7156 28.273C73.0305 28.273 71.5987 27.6887 70.4202 26.5183C69.2416 25.3479 68.6523 23.9184 68.6523 22.2264C68.6523 20.5344 69.2416 19.1032 70.4202 17.9344C71.5987 16.7641 73.0305 16.1797 74.7156 16.1797C76.4007 16.1797 77.8325 16.7674 79.011 17.9427C80.1896 19.118 80.7789 20.5459 80.7789 22.2264C80.7789 23.9068 80.1896 25.3347 79.011 26.5101ZM74.7156 26.411C75.861 26.411 76.831 26.0049 77.6239 25.1911C78.4168 24.3789 78.8141 23.3901 78.8141 22.2247C78.8141 21.0593 78.4168 20.0721 77.6239 19.2583C76.831 18.4462 75.861 18.0384 74.7156 18.0384C73.5702 18.0384 72.5836 18.4445 71.7907 19.2583C70.9979 20.0721 70.6006 21.0593 70.6006 22.2247C70.6006 23.3901 70.9979 24.3773 71.7907 25.1911C72.5836 26.0049 73.5586 26.411 74.7156 26.411Z" fill="#314560"/>
      <path d="M82.8945 28.0758V16.377H86.9433C87.9679 16.377 88.8072 16.6906 89.4626 17.3162C90.1181 17.9419 90.4459 18.7062 90.4459 19.6075C90.4459 20.5748 90.0817 21.3275 89.3551 21.8657C90.6114 22.3824 91.2388 23.3101 91.2388 24.6505C91.2388 25.6277 90.8829 26.4432 90.1728 27.0969C89.4626 27.7506 88.5556 28.0774 87.4548 28.0774H82.8945V28.0758ZM84.8279 21.3028H86.844C87.3621 21.3028 87.7743 21.1542 88.0838 20.8571C88.3917 20.5599 88.5473 20.1819 88.5473 19.7197C88.5473 19.2575 88.3933 18.9075 88.0838 18.5989C87.7759 18.2918 87.3621 18.1383 86.844 18.1383H84.8279V21.3028ZM84.8279 26.3128H87.4051C88.0226 26.3128 88.5009 26.1593 88.8419 25.8522C89.1829 25.5452 89.3534 25.1375 89.3534 24.6323C89.3534 24.1272 89.1829 23.7343 88.8419 23.4207C88.5009 23.107 88.0209 22.9519 87.4051 22.9519H84.8279V26.3128Z" fill="#314560"/>
      <path d="M9.38379 44.4566V32.7578H11.1516L15.2501 39.2997L19.3485 32.7578H21.1163V44.4566H19.183V36.4654L15.9287 41.6718H14.6062L11.3188 36.4489V44.4566H9.38544H9.38379Z" fill="#314560"/>
      <path d="M22.6354 44.4566L27.7569 32.7578H29.4419L34.565 44.4566H32.5489L31.2097 41.3912H25.9559L24.6334 44.4566H22.6338H22.6354ZM28.5845 35.361L26.7008 39.6777H30.4682L28.5845 35.361Z" fill="#314560"/>
      <path d="M43.6859 44.4566L37.9852 38.9861V44.4566H36.0684V32.7578H37.9852V37.7992L42.6944 32.7578H45.1392L39.9185 38.3604L46.2963 44.4566H43.6859Z" fill="#314560"/>
      <path d="M51.6492 44.6539C49.9641 44.6539 48.5323 44.0695 47.3538 42.8992C46.1752 41.7288 45.5859 40.2992 45.5859 38.6072C45.5859 36.9152 46.1752 35.484 47.3538 34.3153C48.5323 33.1449 49.9641 32.5605 51.6492 32.5605C52.7831 32.5605 53.8143 32.8263 54.7396 33.3595C55.6649 33.8927 56.3965 34.6141 56.9378 35.5269L55.2693 36.4827C54.4218 35.1093 53.2151 34.4226 51.6509 34.4226C50.4938 34.4226 49.5189 34.8287 48.726 35.6425C47.9331 36.4563 47.5358 37.4435 47.5358 38.6089C47.5358 39.7743 47.9331 40.7615 48.726 41.5753C49.5189 42.3891 50.4938 42.7952 51.6509 42.7952C53.2151 42.7952 54.4218 42.1085 55.2693 40.735L56.9378 41.6908C56.3982 42.602 55.6649 43.3251 54.7396 43.8582C53.8143 44.3914 52.7847 44.6572 51.6492 44.6572V44.6539Z" fill="#314560"/>
      <path d="M60.4722 44.4566H58.7871V32.7578H60.737V41.1618L67.33 32.7578H69.015V44.4566H67.0817V36.0362L60.4722 44.4566Z" fill="#314560"/>
      <path d="M71.8903 44.4566V32.7578H73.6582L77.755 39.2997L81.8534 32.7578H83.6212V44.4566H81.6879V36.4654L78.432 41.6718H77.1094L73.822 36.4489V44.4566H71.8887H71.8903Z" fill="#314560"/>
      <path d="M86.5127 44.4566V32.7578H93.5675V34.6033H88.4461V37.6027H92.576V39.4483H88.4461V42.6128H93.5675V44.4583H86.5127V44.4566Z" fill="#314560"/>
      <path d="M95.7988 44.4566V32.7578H97.7322V37.6027H103.532V32.7578H105.466V44.4566H103.532V39.4301H97.7322V44.4566H95.7988Z" fill="#314560"/>
      <path d="M115.973 44.4566L110.272 38.9861V44.4566H108.355V32.7578H110.272V37.7992L114.982 32.7578H117.426L112.206 38.3604L118.583 44.4566H115.973Z" fill="#314560"/>
      <path d="M128.233 42.8909C127.054 44.0662 125.622 44.6539 123.937 44.6539C122.252 44.6539 120.82 44.0695 119.642 42.8992C118.463 41.7288 117.874 40.2992 117.874 38.6072C117.874 36.9152 118.463 35.484 119.642 34.3153C120.82 33.1449 122.252 32.5605 123.937 32.5605C125.622 32.5605 127.054 33.1482 128.233 34.3235C129.411 35.4989 130.001 36.9268 130.001 38.6072C130.001 40.2877 129.411 41.7156 128.233 42.8909ZM123.937 42.7919C125.083 42.7919 126.053 42.3858 126.846 41.572C127.638 40.7598 128.036 39.771 128.036 38.6056C128.036 37.4401 127.638 36.453 126.846 35.6392C126.053 34.827 125.083 34.4193 123.937 34.4193C122.792 34.4193 121.805 34.8254 121.012 35.6392C120.22 36.453 119.822 37.4401 119.822 38.6056C119.822 39.771 120.22 40.7581 121.012 41.572C121.805 42.3858 122.78 42.7919 123.937 42.7919Z" fill="#314560"/>
      <path d="M12.6444 60.9998C11.5106 60.9998 10.6035 60.6763 9.92649 60.0275C9.24949 59.3788 8.91016 58.5666 8.91016 57.5894C8.91016 56.8317 9.11375 56.2077 9.52095 55.7191C9.92815 55.2304 10.5058 54.755 11.2557 54.2945C10.3519 53.4377 9.90001 52.581 9.90001 51.7243C9.90001 50.9336 10.2029 50.2716 10.8088 49.7384C11.4146 49.2052 12.1644 48.9395 13.0566 48.9395C13.9488 48.9395 14.6804 49.2135 15.2548 49.7632C15.8275 50.3129 16.1139 50.9715 16.1139 51.7408C16.1139 52.3994 15.9219 52.9524 15.5362 53.3965C15.1505 53.8422 14.6275 54.24 13.967 54.5916L16.2149 56.7673L17.933 54.6246L19.2059 55.6794L17.3885 57.9047L20.4292 60.838H18.034L16.3158 59.1741C15.71 59.8228 15.124 60.29 14.5563 60.5756C13.9885 60.8611 13.3529 61.0031 12.6478 61.0031L12.6444 60.9998ZM12.6776 59.3854C13.5035 59.3854 14.3245 58.9397 15.1389 58.0516L12.4458 55.4318H12.4293C11.2838 56.0575 10.7111 56.7887 10.7111 57.624C10.7111 58.1836 10.9064 58.6178 11.2971 58.9265C11.6877 59.2335 12.1479 59.387 12.6759 59.387L12.6776 59.3854ZM11.6529 51.7391C11.6529 51.8382 11.6612 51.9306 11.6778 52.0197C11.6943 52.1072 11.7274 52.198 11.7771 52.2921C11.8267 52.3862 11.8681 52.4621 11.9012 52.5232C11.9343 52.5843 11.9972 52.6635 12.0916 52.7626C12.1843 52.8616 12.2571 52.9359 12.3068 52.9854C12.3564 53.035 12.4442 53.1224 12.5716 53.2495C12.6974 53.3767 12.7885 53.4658 12.8447 53.5219L12.8944 53.4889C13.3893 53.2363 13.7535 52.992 13.9852 52.756C14.217 52.5199 14.3328 52.2145 14.3328 51.8415C14.3328 51.4453 14.212 51.1217 13.9687 50.8692C13.727 50.6166 13.4125 50.4895 13.0268 50.4895C12.6411 50.4895 12.3167 50.6051 12.0519 50.8362C11.787 51.0673 11.6546 51.3694 11.6546 51.7424L11.6529 51.7391Z" fill="#D12D38"/>
      <path d="M32.8687 60.8356V50.9823H27.2342V60.8356H25.3174V49.1367H34.7855V60.8356H32.8687Z" fill="#314560"/>
      <path d="M36.2888 60.8356L41.4102 49.1367H43.0952L48.2166 60.8356H46.2005L44.8631 57.7701H39.6092L38.2867 60.8356H36.2871H36.2888ZM42.2378 51.7399L40.3541 56.0566H44.1215L42.2378 51.7399Z" fill="#314560"/>
      <path d="M56.3799 50.2312C57.1396 50.9624 57.5203 51.8704 57.5203 52.9582C57.5203 54.046 57.1396 54.9556 56.3799 55.6852C55.6201 56.4165 54.6451 56.7813 53.455 56.7813H51.6541V60.8356H49.7207V49.1367H53.455C54.6451 49.1367 55.6201 49.5015 56.3799 50.2328V50.2312ZM55.0325 54.3993C55.4231 54.0196 55.6184 53.5393 55.6184 52.9582C55.6184 52.3771 55.4231 51.8951 55.0325 51.5171C54.6418 51.1374 54.1105 50.9492 53.4384 50.9492H51.6541V54.9705H53.4384C54.1105 54.9705 54.6418 54.7806 55.0325 54.4026V54.3993Z" fill="#314560"/>
      <path d="M61.7167 60.8356V50.9823H58.3135V49.1367H67.0699V50.9823H63.6501V60.8356H61.7167Z" fill="#314560"/>
      <path d="M68.8047 60.8356V49.1367H70.738V53.9817H76.5381V49.1367H78.4715V60.8356H76.5381V55.809H70.738V60.8356H68.8047Z" fill="#314560"/>
      <path d="M81.3633 60.8356V49.1367H88.418V50.9823H83.2966V53.9817H87.4282V55.8272H83.2966V58.99H88.418V60.8356H81.3633Z" fill="#314560"/>
      <path d="M97.3066 50.2312C98.0664 50.9624 98.4471 51.8704 98.4471 52.9582C98.4471 54.046 98.0664 54.9556 97.3066 55.6852C96.5468 56.4165 95.5719 56.7813 94.3818 56.7813H92.5808V60.8356H90.6475V49.1367H94.3818C95.5719 49.1367 96.5468 49.5015 97.3066 50.2328V50.2312ZM95.9592 54.3993C96.3499 54.0196 96.5452 53.5393 96.5452 52.9582C96.5452 52.3771 96.3499 51.8951 95.9592 51.5171C95.5686 51.1374 95.0372 50.9492 94.3652 50.9492H92.5808V54.9705H94.3652C95.0372 54.9705 95.5686 54.7806 95.9592 54.4026V54.3993Z" fill="#314560"/>
      <path d="M102.314 53.602H104.38C105.58 53.602 106.55 53.9371 107.288 54.6073C108.026 55.2775 108.396 56.1507 108.396 57.227C108.396 58.3033 108.023 59.16 107.28 59.8302C106.537 60.5005 105.57 60.8356 104.38 60.8356H100.364V49.1367H102.314V53.602ZM102.314 59.0395H104.347C105.019 59.0395 105.542 58.8827 105.916 58.5707C106.29 58.2571 106.477 57.8097 106.477 57.227C106.477 56.6443 106.29 56.1804 105.916 55.8668C105.542 55.5532 105.019 55.398 104.347 55.398H102.314V59.0395ZM109.965 60.8356V49.1367H111.898V60.8356H109.965Z" fill="#314560"/>
    </g>
    <defs>
      <clipPath id="clip0_554_25478">
        <rect width="130" height="61" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default LogoMain
