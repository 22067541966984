import classNames from 'classnames'
import { useEffect } from 'react'

import { EMPTY_VALUE_ERR_MSG } from '@/constants'

import RadioButton from './components/RadioButton'
import { RB_TYPES, RB_COLORS } from './constants'

import styles from './index.module.css'

const RadioButtonGroup = ({
  type = RB_TYPES.radio,
  color = RB_COLORS.main,
  className,
  items = [],
  current,
  required,
  onChange = () => {},
  onError = () => {},
}) => {
  useEffect(() => {
    if (required && !current) {
      onError(EMPTY_VALUE_ERR_MSG)
    }
  }, [current, onError, required])

  const handleChange = (id) => () => {
    if (current !== id) {
      onChange(id)
    } else if (!required) {
      onChange(undefined)
    }
  }
  
  return (
    <div className={classNames(className, styles.group)}>
      {items.map((item) => {
        return (
          <RadioButton
            {...item}
            color={color}
            type={type}
            key={item.id}
            checked={current === item.id}
            onChange={handleChange}
          />
        )
      })}
    </div>
  )
}

export default RadioButtonGroup

export { RB_TYPES, RB_COLORS } from './constants'
