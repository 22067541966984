/* eslint-disable max-len */
import React from 'react'

const ArrowLeftTop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    {...props}
  >
    <path d="M1.92308 1H13M13 1V11.4615M13 1L1 13" stroke="white" strokeWidth="2"/>
  </svg>
)

export default ArrowLeftTop
