/* eslint-disable max-len */
import React from 'react'

const Restart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={24}
    viewBox="0 0 22 24"
    {...props}
  >
    <path d="M22 13.0679C22 19.0949 17.066 24 11 24C4.934 24 0 19.0949 0 13.0679C0 9.13633 2.14023 5.48979 5.58603 3.55026C5.94978 3.34626 6.41013 3.47376 6.6154 3.83377C6.82066 4.19527 6.69237 4.65278 6.33013 4.85678C3.35675 6.5293 1.50933 9.67484 1.50933 13.0679C1.50933 18.2684 5.76715 22.5 11 22.5C16.2328 22.5 20.4907 18.2684 20.4907 13.0679C20.4907 8.14332 16.6238 4.01077 11.6867 3.65976C11.6535 3.65676 11.6218 3.64926 11.5901 3.64326L12.9667 4.76978C13.2897 5.03228 13.3349 5.50479 13.0693 5.82579C12.9214 6.00579 12.704 6.09879 12.4867 6.09879C12.3176 6.09879 12.1486 6.04179 12.0082 5.92779L9.19786 3.62826C9.02429 3.48576 8.92316 3.27276 8.92316 3.04926C8.92316 2.82576 9.02429 2.61275 9.19786 2.47025L12.0082 0.170725C12.3297 -0.0917785 12.8052 -0.046778 13.0693 0.272726C13.3349 0.592229 13.2897 1.06473 12.9667 1.32874L11.9313 2.17625C17.5882 2.65025 22 7.40531 22 13.0679Z" fill="#314560"/>
  </svg>
)

export default Restart
