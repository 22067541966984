import React, { memo } from 'react'
import { Handle, Position, useEdges } from 'reactflow'

const Block = ({ data, id }) => {
  const edges = useEdges()
  const canBeSource = !edges.some(e => e.source === id)

  return (
    <>
      <div>
        Блок {data.index + 1}. {data.label}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={canBeSource}
      />
    </>
  )
}

export default memo(Block)
