import classNames from 'classnames'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

import useClickOutside from '@/utils/hooks/useClickOutside'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const setActiveBlock = (ref, isCloseCurrent = false) => {
  if (ref.current && isCloseCurrent) {
    ref.current.classList.remove(styles['active'])
    return
  }

  if (ref.current) {
    const parent = ref.current.parentElement
    if (parent) {
      Array.from(parent.children).forEach(child => {
        child.classList.remove(styles['active'])
      })
      ref.current.classList.add(styles['active'])
    }
  }
}

const OperationsCard = ({
  className,
  title,
  items = [],
  style,
  position,
}) => {
  const blockRef = useRef()
  const blockItemsRef = useRef()
  useClickOutside(blockItemsRef, () => setActiveBlock(blockRef, true))

  if (items.length === 1) {
    return (
      <div
        className={classNames(styles.root, className)}
        style={style}
      >
        <Link
          className={styles.link}
          to={title.link}
        >
          {title.label}
        </Link>
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.root, className)}
      style={style}
      ref={blockRef}
    >
      <div
        className={styles.title}
        onClick={() => setActiveBlock(blockRef)}
      >
        <Icon className={styles.icon} type={ICONS_TYPES.plus} />
        {title.label}
      </div>
      {items &&
        <div className={classNames(styles.itemsContainer, styles[position])} ref={blockItemsRef}>
          <Icon
            className={styles.iconClose}
            type={ICONS_TYPES.close}
            size={8}
            onClick={() =>setActiveBlock(blockRef, true)}
          />
          <div className={styles.titleItems}>
            {title.label}
          </div>
          <div className={styles.items}>
            {items && items.map((item, index) => (
              <div
                key={index}
                className={styles.item}
              >
                <Link
                  to={item.link}
                >
                  <Icon
                    className={styles.iconArrow}
                    type={ICONS_TYPES.arrowLeftTop}
                    size={8}
                  />
                  <span>{item.label}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default OperationsCard

export { CARD_POSITIONS } from './constants'
