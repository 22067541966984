import { Outlet } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import classNames from 'classnames'

import FlyingLinks from '@/components/FlyingLinks'
import BigHeader from '@/components/BigHeader'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import { StoreContext } from '@/store'
import { LAYOUT_TYPES } from '@/constants'

import styles from './index.module.css'

const Root = () => {
  const store = useContext(StoreContext)

  const { layoutStore } = store

  const isShowHeader = layoutStore.props?.type === LAYOUT_TYPES.auth ||
    layoutStore?.props?.type !== LAYOUT_TYPES.operation

  const isShowFooter = layoutStore.props?.type === LAYOUT_TYPES.operation
  const isShowBigHeader = layoutStore.props?.type === LAYOUT_TYPES.operation
  const isShowLinks = layoutStore.props?.type === LAYOUT_TYPES.operation

  return (
    <div className={classNames(
      styles.root,
      layoutStore.props?.type && styles[layoutStore.props.type]
    )}>
      {isShowLinks && <FlyingLinks />}
      {isShowHeader && <Header />}
      {isShowBigHeader && <BigHeader />}
      <div className={classNames('container', styles.main)}>
        <Outlet />
      </div>
      {isShowFooter && <Footer />}
    </div>
  )
}

export default observer(Root)
