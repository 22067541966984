import { observer } from 'mobx-react-lite'

import Block from './Block'

import styles from './index.module.css'


const ListView = ({
  blocks,
}) => (
  <div className={styles.root}>
    {blocks.map((block, i) => (
      <Block key={block.id || i} block={block} />
    ))}
  </div>
)

export default observer(ListView)
