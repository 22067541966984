/* eslint-disable max-len */
import React from 'react'

const Person = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={32}
    viewBox="0 0 27 32"
    {...props}
  >
    <path d="M12.4583 17.6875L6.10589 19.473C3.08638 20.3217 1 23.0754 1 26.2119V31.2595H26V26.2119C26 23.0754 23.9136 20.3217 20.8941 19.473L14.5417 17.6875M12.4583 17.6875V17.6875C12.4583 19.7649 12.1628 21.8318 11.5805 23.826L11.1241 25.3894L13.4181 27.6744L15.794 25.3894L15.3694 23.8401C14.8201 21.8354 14.5417 19.7661 14.5417 17.6875V17.6875M12.4583 17.6875H14.5417" stroke="#D12D38"/>
    <path d="M10.1167 15.9032C11.0034 17.4248 13.0073 17.7234 13.8984 17.6825C18.593 18.0261 18.9842 10.5857 18.593 6.82261C13.3768 6.92077 10.7687 5.75912 10.1167 5.16602C9.12561 5.75503 8.65752 9.19072 8.46191 10.2133C8.70099 11.8903 9.22993 14.3816 10.1167 15.9032Z" stroke="#D12D38"/>
    <path d="M9.7183 3.54007C9.19489 3.45868 7.53298 2.92291 6.48831 5.55449C5.83403 7.20266 6.65989 10.2105 8.46225 10.2105M10.1109 5.55449C9.67468 4.82197 9.19488 3.01509 10.7651 1.64773C12.7279 -0.0614831 15.0615 2.03125 18.2892 3.11276C20.2228 3.76063 19.7745 5.73371 18.7073 6.72919" stroke="#D12D38"/>
  </svg>
)

export default Person
