import Modal from '@/components/Modal'
import Input from '@/components/Input'
import { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const InputModal = ({
  title,
  children,
  form,
  onSuccess,
  onClose,
  ...other
}) => {
  const hasFormError = form.hasErrors

  return (
    <Modal
      title={title}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: 'Сохранить',
          disabled: hasFormError,
          onClick: () => {
            onSuccess(form.get('data.value', ''))
            onClose()
          },
        },
      ]}
    >
      <Input
        {...other}
        value={form.get('data.value', '')}
        error={form.get('errors.value', '')}
        onChange={form.set('data.value')}
        onError={form.set('errors.value')}
      />
    </Modal>
  )
}

export default InputModal
