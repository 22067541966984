/* eslint-disable max-len */
import React from 'react'

const Restrictions = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    viewBox="0 0 27 27"
    {...props}
  >
    <path d="M10.8022 5.6282C11.6931 3.77032 14.3227 3.72718 15.274 5.55485L24.274 22.8457C25.1403 24.5101 23.9327 26.5 22.0564 26.5H4.76554C2.92511 26.5 1.71555 24.5786 2.51129 22.9191L10.8022 5.6282Z" stroke="#D12D38"/>
    <path d="M13.2881 10.5762V18.37" stroke="#D12D38"/>
    <path d="M13.2881 22.8224V21.1523" stroke="#D12D38"/>
  </svg>
)

export default Restrictions
