import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '@/store'

import Button, { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const EmptyBlocks = ({
  onCreate,
}) => {
  const store = useContext(StoreContext)
  const { formsStore } = store

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.root}>
        <span>Здесь пока ничего нет...</span>
        <Button
          type={BUTTON_TYPES.border}
          onClick={() => {
            const formName = 'createBlock'
            formsStore.createForm(formName, {
              modalComponent: 'InputModal',
              props: {
                title: 'Создать блок',
                label: 'Наименование',
                placeholder: 'Введите наименование *',
                required: true,
                onSuccess: text => onCreate(0, text),
              },
            })
          }}
        >
          + Создать первый блок
        </Button>
      </div>
    </div>
  )
}

export default observer(EmptyBlocks)
