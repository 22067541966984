import Modal from '@/components/Modal'
import { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const ConfirmModal = ({
  title,
  text,
  onSuccess,
  onClose,
}) => {
  return (
    <Modal
      title={title}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: 'Подтвердить',
          onClick: () => {
            onSuccess()
            onClose()
          },
        },
      ]}
    >
      {text}
    </Modal>
  )
}

export default ConfirmModal
