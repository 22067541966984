import { useState, useEffect } from 'react'

const useWidthObserver = (ref) => {
  const [width, setWidth] = useState(ref?.current?.offsetWidth || 0)

  useEffect(() => {
    if (!ref.current) return

    const handleResize = (entries) => {
      for (let entry of entries) {
        setTimeout(() => {
          setWidth(entry.contentRect.width)
        }, 100)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(ref.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [ref])

  return width
}

export default useWidthObserver
