/* eslint-disable max-len */
export const SUPPORT_EMAIL = 'support@kkmp.legal'

export const EMPTY_VALUE_ERR_MSG = 'Значение обязательно'

export const INCORRECT_PHONE_FORMAT = 'Некорректный номер телефона'

export const INCORRECT_MAIL_FORMAT = 'Некорректный E-Mail'

export const INCORRECT_MAIL_OR_PASSWORD = 'Неверный E-Mail пользователя или пароль'

export const LAYOUT_TYPES = {
  auth: 'auth',
  main: 'main',
  quiz: 'quiz',
  user: 'user',
  profile: 'profile',
  operation: 'operation',
}

export const INDUSTRIES = [
  {
    value: 'Сельское, лесное хозяйство, охота, рыболовство и рыбоводство',
    label: 'Сельское, лесное хозяйство, охота, рыболовство и рыбоводство',
  },
  {
    value: 'Добыча полезных ископаемых',
    label: 'Добыча полезных ископаемых',
  },
  {
    value: 'Обрабатывающие производства',
    label: 'Обрабатывающие производства',
  },
  {
    value: 'Обеспечение электрической энергией, газом и паром; кондиционирование воздуха',
    label: 'Обеспечение электрической энергией, газом и паром; кондиционирование воздуха',
  },
  {
    value: 'Водоснабжение; водоотведение, организация сбора и утилизации отходов, деятельность по ликвидации загрязнений',
    label: 'Водоснабжение; водоотведение, организация сбора и утилизации отходов, деятельность по ликвидации загрязнений',
  },
  {
    value: 'Строительство',
    label: 'Строительство',
  },
  {
    value: 'Торговля оптовая и розничная; ремонт автотранспортных средств и мотоциклов',
    label: 'Торговля оптовая и розничная; ремонт автотранспортных средств и мотоциклов',
  },
  {
    value: 'Транспортировка и хранение',
    label: 'Транспортировка и хранение',
  },
  {
    value: 'Деятельность гостиниц и предприятий общественного питания',
    label: 'Деятельность гостиниц и предприятий общественного питания',
  },
  {
    value: 'Деятельность в области информации и связи',
    label: 'Деятельность в области информации и связи',
  },
  {
    value: 'Деятельность финансовая и страховая',
    label: 'Деятельность финансовая и страховая',
  },
  {
    value: 'Деятельность по операциям с недвижимым имуществом',
    label: 'Деятельность по операциям с недвижимым имуществом',
  },
  {
    value: 'Деятельность профессиональная, научная и техническая',
    label: 'Деятельность профессиональная, научная и техническая',
  },
  {
    value: 'Деятельность административная и сопутствующие дополнительные услуги',
    label: 'Деятельность административная и сопутствующие дополнительные услуги',
  },
  {
    value: 'Государственное управление и обеспечение военной безопасности; социальное обеспечение',
    label: 'Государственное управление и обеспечение военной безопасности; социальное обеспечение',
  },
  {
    value: 'Образование',
    label: 'Образование',
  },
  {
    value: 'Деятельность в области здравоохранения и социальных услуг',
    label: 'Деятельность в области здравоохранения и социальных услуг',
  },
  {
    value: 'Деятельность в области культуры, спорта, организации досуга и развлечений',
    label: 'Деятельность в области культуры, спорта, организации досуга и развлечений',
  },
  {
    value: 'Предоставление прочих видов услуг',
    label: 'Предоставление прочих видов услуг',
  },
  {
    value: 'Деятельность домашних хозяйств как работодателей; недифференцированная деятельность частных домашних хозяйств по производству товаров и оказанию услуг для собственного потребления',
    label: 'Деятельность домашних хозяйств как работодателей; недифференцированная деятельность частных домашних хозяйств по производству товаров и оказанию услуг для собственного потребления',
  },
  {
    value: 'Деятельность экстерриториальных организаций и органов',
    label: 'Деятельность экстерриториальных организаций и органов',
  },
]
