import { useEffect, useMemo } from 'react'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import { getApiPath } from '@/store/helpers/fetch'

import styles from './index.module.css'
import { INDUSTRIES } from '@/constants'


const patronymicFormat = v => {
  const value = v.trim().replace('.', '')
  return (value[0] || '').toUpperCase()
}

const patronymicUnformat = v => (v ? v.trim() + '.' : '').toUpperCase()

const ClintEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const userId = form.get('data.id')
  const endpoint = getApiPath(userId ? 'users/' + userId : 'users/')
  const method = userId ? 'PATCH' : 'POST'

  const fullName = form.get('data.fullname', '')
  const nameParts = useMemo(() => fullName.split(' '), [fullName])
  const companyIndustry = form.get('data.client.industry', '')

  useEffect(() => {
    let surname
    let name
    let patronymic
    if (nameParts.length === 2) {
      surname = nameParts[0]
      name = nameParts[1]
    } else {
      surname = nameParts[0]
      patronymic = nameParts[1]
      name = nameParts[2]
    }
    form.set('data.role')('client')
    form.set('data.surname')(surname)
    form.set('data.name')(name)
    form.set('data.patronymic')(patronymic)
  }, [form, nameParts])

  return (
    <Modal
      title="Профиль клиента"
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: userId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(
            endpoint,
            method,
            {
              transform: data => {
                return {
                  ...data,
                  surname: undefined,
                  name: undefined,
                  patronymic: undefined,
                  fullname: [data.surname, data.patronymic, data.name]
                    .filter(p => p !== undefined).join(' '),
                }
              },
            }
          )
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        label="Фамилия*"
        placeholder="Фамилия"
        required
        allowRegex={/[a-zа-я-]/i}
        value={form.get('data.surname', '')}
        error={form.get('errors.surname', '')}
        onChange={form.set('data.surname')}
        onError={form.set('errors.surname')}
      />
      <Input
        label="Имя*"
        placeholder="Имя"
        required
        allowRegex={/[a-zа-я-]/i}
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Input
        label="Отчество (только первая буква)"
        placeholder="В формате «первая буква Отчества»"
        customFormat={{
          format: patronymicFormat,
          unformat: patronymicUnformat,
        }}
        allowRegex={/[a-zа-я]/i}
        value={form.get('data.patronymic', '')}
        error={form.get('errors.patronymic', '')}
        onChange={form.set('data.patronymic')}
        onError={form.set('errors.patronymic')}
      />
      <Input
        label="Логин (email)*"
        placeholder="Почта"
        required
        type="mail"
        value={form.get('data.username', '')}
        error={form.get('errors.username', '')}
        onChange={form.set('data.username')}
        onError={form.set('errors.username')}
      />
      {!userId && (
        <Input
          label="Пароль*"
          placeholder="Пароль"
          required
          type="password"
          value={form.get('data.password', '')}
          error={form.get('errors.password', '')}
          onChange={form.set('data.password')}
          onError={form.set('errors.password')}
        />
      )}
      <Input
        label="Наименование компании*"
        placeholder="Компания"
        required
        type="text"
        value={form.get('data.client.title', '')}
        error={form.get('errors.client.title', '')}
        onChange={form.set('data.client.title')}
        onError={form.set('errors.client.title')}
      />
      <Select
        label="Вид деятельности компании"
        placeholder="Не выбрано"
        options={INDUSTRIES}
        values={companyIndustry ? [companyIndustry] : []}
        error={form.get('errors.client.industry', '')}
        onChange={v => form.set('data.client.industry')(v[0])}
        onError={form.set('errors.client.industry')}
      />
      <Input
        label="Телефон"
        placeholder="+ _ (___) ___–__–__"
        type="phone"
        value={form.get('data.client.phone', '')}
        error={form.get('errors.client.phone', '')}
        onChange={form.set('data.client.phone')}
        onError={form.set('errors.client.phone')}
      />
      <Input
        label="Дополнительный email"
        type="mail"
        value={form.get('data.client.additional_emails.0', '')}
        error={form.get('errors.client.additional_emails.0', '')}
        onChange={form.set('data.client.additional_emails.0')}
        onError={form.set('errors.client.additional_emails.0')}
      />
    </Modal>
  )
}

export default ClintEditModal
