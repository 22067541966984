import classNames from 'classnames'
import { useRef, useEffect, useState } from 'react'
import useWidthObserver from '@/utils/hooks/useWidthObserver'

import OperationsCard, { CARD_POSITIONS } from '@/components/OperationsCard'
import Loader from '@/components/Loader/'

import styles from './index.module.css'

const getLastRowIndices = (elements, rowLength) => {
  const lastRowIndices = []
  const totalElements = elements.length
  const startIndexOfLastRow = (totalElements % rowLength !== 0) ?
    totalElements - (totalElements % rowLength) :
    totalElements - rowLength

  for (let i = startIndexOfLastRow; i < totalElements; i++) {
    lastRowIndices.push(i)
  }

  return lastRowIndices
}

const OperationsList = ({
  className,
  dataArray = [],
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataWithPositions, setDataWithPositions] = useState()

  const container = useRef(null)
  const containerWidth = useWidthObserver(container)

  useEffect(() => {
    if (dataArray.length > 0) {
      const dataLength = dataArray.length - 1
      const blocksInRow = Math.floor(containerWidth / 330)
      const lastRowIndices = getLastRowIndices(dataArray, blocksInRow)

      const dataPositions = dataArray.map((item, index) => {

        if (item.items) {
          const isLastInRow = index > 0 && ((index + 1) % blocksInRow) === 0
          const isInLastRow = lastRowIndices.includes(index)

          if ((!isLastInRow && !isInLastRow) ||
          (blocksInRow === 1 && index < dataLength)) {
            item.position = CARD_POSITIONS.topLeft
            return item
          }

          if (isLastInRow && index < dataLength) {
            item.position = CARD_POSITIONS.topRight
            return item
          }

          if (!isLastInRow && isInLastRow) {
            item.position = CARD_POSITIONS.bottomLeft
            return item
          }

          item.position = CARD_POSITIONS.bottomRight
        }

        return item
      })

      setDataWithPositions(dataPositions)
      setIsLoading(false)
    }
  }, [dataArray, containerWidth])

  return (
    <div className={classNames(styles.root, className)}>
      <h2 className={styles.title}>Сделки (операции)</h2>
      <div
        className={styles.cards}
        ref={container}
      >
        {isLoading && <Loader />}
        {!isLoading &&
          dataWithPositions.map((item, index) => (
            <OperationsCard
              key={index}
              position={item.position}
              title={{
                label: item.title,
                link: item.link,
              }}
              items={item.items}
            />
          ))
        }
      </div>
    </div>
  )
}

export default OperationsList
