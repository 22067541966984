/* eslint-disable max-len */
import React from 'react'

const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    {...props}
  >
    <path d="M13 1L1 13" strokeWidth="2"/>
    <path d="M0.999999 1L13 13" strokeWidth="2"/>
  </svg>
)

export default Close
