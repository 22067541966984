/* eslint-disable max-len */
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './index.module.css'

const Footer = ({ className }) => {

  return (
    <div className={classNames(
      styles.rootWrapper,
      className
    )}>
      <div className={'container'}>
        <div className={styles.root}>
          <div className={styles.copyright}>
            <span>© 2024</span>  ООО «Кучер Кулешов Максименко и партнеры»  <i>|</i>  <span className={styles.linkWrapper}><Link to="/privacy-policy">Политика конфиденциальности</Link></span>
          </div>
          <div className={styles.developer}>Разработка – Makros</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
