export const VIEW_DICT = {
  tree: 'Дерево',
  list: 'Список',
  result: 'Результаты',
}

export const VIEW_TYPES = Object.keys(VIEW_DICT).reduce((memo, curr) => ({
  ...memo,
  [curr]: curr,
}), {})

export const transformNodesEdgesToBlock = ({ nodes = [], edges = [] }) => {
  if (!nodes.length) {
    return undefined
  }
  const blockNode = nodes.find(n => n.type === 'block')
  if (!blockNode) return {}

  const blockEdge = edges.find(e => e.source === blockNode.id)
  const blockNext = blockEdge?.target

  return {
    id: blockNode.id,
    text: blockNode.data?.label,
    next_id: blockNext,
    questionary: nodes.reduce((memo, n) => {
      if (n.type === 'question') {
        const edge = edges.find(e => e.target === n.id)
        const parent_id = edge?.source
        return {
          ...memo,
          [n.id]: {
            id: n.id,
            type: n.type,
            question_type: n.data?.type,
            text: n.data?.label,
            parent_id: parent_id === blockNode.id ? undefined : parent_id,
            choices: edges.filter(e => e.source === n.id).map(e => e.target),
          },
        }
      }
      if (n.type === 'choice') {
        const targetEdge = edges.find(e => e.source === n.id)
        const next_id = targetEdge?.target
        const sourceEdge = edges.find(e => e.target === n.id)
        const parent_id = sourceEdge?.source
        return {
          ...memo,
          [n.id]: {
            id: n.id,
            type: n.type,
            text: n.data?.label,
            value: n.data?.value,
            next_id,
            parent_id,
          },
        }
      }
      return memo
    }, {}),
  }
}
