import { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import RadioButtonGroup, { RB_TYPES, RB_COLORS } from '@/components/RadioButtonGroup'
import Search, { SEARCH_TYPES } from '@/components/Search'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const Countries = () => {
  const store = useContext(StoreContext)

  const [statusFilter, setStatusFilter] = useState()
  const [search, setSearch] = useState()

  const { httpStore, formsStore } = store

  const params = {
    limit: 1000,
  }

  if (statusFilter === 'friendly') {
    params['is_friendly'] = true
  } else if (statusFilter === 'unfriendly') {
    params['is_friendly'] = false
  }

  if (search) {
    params['title__ilike'] = search
  }

  const statesEndpoint = 'states/'
  const dataEndpoint = getApiPath(statesEndpoint)
  const data = httpStore.fetchRequest(dataEndpoint, { params }).get('data', [])

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Список государств
        </h1>
        <div className={pageStyles.headerControls}>
          <RadioButtonGroup
            type={RB_TYPES.check}
            color={RB_COLORS.blue}
            items={[
              { id: 'friendly', label: 'Дружественные' },
              { id: 'unfriendly', label: 'Не дружественные' },
            ]}
            current={statusFilter}
            onChange={setStatusFilter}
          />
          <Search
            className={styles.search}
            type={SEARCH_TYPES.mini}
            onSearch={setSearch}
          />
        </div>
      </div>
      <div className={pageStyles.block}>
        <Table
          data={data}
          head={[
            {
              title: 'Государство',
            },
            {
              title: 'Статус',
            },
          ]}
          body={[
            {
              model: item => item.title,
            },
            {
              model: item => (
                <div className={styles.status}>
                  <div className={styles[item.is_friendly ? 'friendly' : 'unfriendly']}>
                    {item.is_friendly ? 'Дружественное' : 'Недружественное'}
                  </div>
                  <Button
                    type={BUTTON_TYPES.text}
                    color={BUTTON_COLORS.blue}
                    onClick={() => {
                      const url = getApiPath(statesEndpoint + item.id)
                      formsStore.sendQuickForm(
                        {
                          form: {
                            data: {
                              is_friendly: !item.is_friendly,
                            },
                          },
                        },
                        url,
                        'PATCH'
                      )
                        .then(() => {
                          httpStore.fetchRequest(dataEndpoint, {
                            params,
                            cacheTime: 0,
                          })
                        })
                    }}
                  >
                    Сменить статус
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Countries)
