import Modal from '@/components/Modal'
import Input from '@/components/Input'
import { BUTTON_TYPES } from '@/components/Button'

import { getApiPath } from '../../store/helpers/fetch'

import styles from './index.module.css'


const OperationEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const operationsId = form.get('data.id')
  const endpoint = getApiPath(operationsId ? 'operations/' + operationsId : 'operations/')
  const method = operationsId ? 'PATCH' : 'POST'

  return (
    <Modal
      title={operationsId ? 'Редактировать операцию' : 'Создать операцию'}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: operationsId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(endpoint, method)
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        required
        label="Наименование"
        type="text"
        placeholder="Наименование *"
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Input
        required
        label="Порядок в списке на сайте"
        placeholder="Номер *"
        allowRegex={/[0-9]/}
        value={form.get('data.order', '')}
        error={form.get('errors.order', '')}
        onChange={form.set('data.order')}
        onError={form.set('errors.order')}
      />
    </Modal>
  )
}

export default OperationEditModal
