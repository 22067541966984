import { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'


const ResultsTable = ({ formName }) => {
  const store = useContext(StoreContext)

  const { formsStore } = store

  const formObject = formsStore.getForm(formName)

  if (!formObject) {
    return null
  }

  const { form } = formObject

  const blocks = form?.get('data.blocks', [])
  const results = form?.get('data.results', [])

  return (
    <div className={styles.root}>
      <Table
        data={results}
        head={[
          {
            title: '№',
          },
          {
            title: 'Результат',
          },
          ...blocks.map((b, i) => ({
            title: `Блок ${i + 1}. ${b.text}`,
          })),
        ]}
        body={[
          {
            model: (item, i) => i + 1,
          },
          {
            model: (item, i) => {
              if (item.name) {
                return (
                  <div className={styles.result}>
                    {item.name}
                    <Icon
                      className={styles.edit}
                      type={ICONS_TYPES.edit}
                      size={18}
                      onClick={() => {
                        const formName = 'editResult' + i
                        formsStore.createForm(formName, {
                          modalComponent: 'ResultEditModal',
                          form: {
                            data: item,
                          },
                          props: {
                            title: 'Изменить результат',
                            onSuccess: form.set('data.results.' + i),
                          },
                        })
                      }}
                    />
                  </div>
                )
              }
              return (
                <Button
                  type={BUTTON_TYPES.text}
                  onClick={() => {
                    const formName = 'editResult' + i
                    formsStore.createForm(formName, {
                      modalComponent: 'ResultEditModal',
                      form: {
                        data: item,
                      },
                      props: {
                        title: 'Создать результат',
                        onSuccess: form.set('data.results.' + i),
                      },
                    })
                  }}
                >
                  + Добавить
                </Button>
              )
            },
          },
          ...blocks.map((b, i) => ({
            model: item => {
              const answers = []
              let answer = item.choices[i]
              while (answer) {
                const node = b.questionary[answer]
                if (node?.type === 'choice') {
                  answers.push(node.text)
                }
                answer = node?.parent_id
              }
              return answers.reverse().join(' - ')
            },
          })),
        ]}
      />
    </div>
  )
}

export default observer(ResultsTable)
