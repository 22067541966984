import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const ReferenceInformation = ({ className }) => {

  const listItems = [
    {
      link: '#',
      title: 'Список недружественных государств',
    },
    {
      link: '#',
      title: 'Термины и определения',
    },
    {
      link: '#',
      title: 'Контрсанкционный дайджест',
    },
  ]

  return (
    <div className={classNames(
      styles.root,
      className
    )}>
      <div className={styles.title}>Справочная информация</div>
      <div className={styles.items}>
        {listItems.map((item, index) => (
          <Link
            className={styles.item}
            key={index}
            to={item.link}
          >
            <Icon
              className={styles.icon}
              type={ICONS_TYPES.arrowLeftTop}
              width={12}
            />
            <span>{item.title}</span>
          </Link>
        ))}
      </div>
      <div className={styles.text}>
        Обращаем ваше внимание, что информация, предоставляемая настоящим сервисом, носит
        исключительно справочный характер и не является юридической консультацией. Для
        кончательного вывода о применении того или иного ограничения к конкретной сделке (операции)
        необходим комплексный анализ фактических обстоятельств. Информация, используемая
        настоящим сервисом, основана на публично доступных источниках.<br />
        Информация актуальна по состоянию на [дд.мм.гггг].
      </div>
    </div>
  )
}

export default ReferenceInformation
