import { useContext } from 'react'
import classNames from 'classnames'

import { NavLink } from 'react-router-dom'

import DropDownList from '@/components/DropDownList'

import styles from './index.module.css'

import { NAV_ITEMS } from './constants'
import { StoreContext } from '../../store'

const NavBar = ({ className }) => {
  const store = useContext(StoreContext)
  const { authStore } = store

  return (
    <nav className={classNames(styles.root, className)}>
      {NAV_ITEMS.length > 0 && NAV_ITEMS.map((nav, index) => {
        if (nav.available && !nav.available(authStore)) {
          return null
        }
        if (nav.children) {
          return (
            <DropDownList
              key={index}
              HeadComponent={() => nav.label}
            >
              {nav.children.map((child, childIndex) => {
                if (child.available && !child.available(authStore)) {
                  return null
                }
                return (
                  <NavLink
                    key={childIndex}
                    to={child.link}
                    className={styles.link}
                  >
                    {child.label}
                  </NavLink>
                )
              })}
            </DropDownList>
          )
        }

        return (
          <NavLink
            key={index}
            to={nav.link}
            className={styles.link}
          >
            {nav.label}
          </NavLink>
        )
      })}
    </nav>
  )
}

export default NavBar
