import Modal from '@/components/Modal'
import Input from '@/components/Input'
import { BUTTON_TYPES } from '@/components/Button'

import { getApiPath } from '@/store/helpers/fetch'

import styles from './index.module.css'


const TermsEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const termsId = form.get('data.id')
  const endpoint = getApiPath(termsId ? 'terms/' + termsId : 'terms/')
  const method = termsId ? 'PATCH' : 'POST'

  return (
    <Modal
      title={termsId ? 'Редактировать термин' : 'Создать термин'}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: termsId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(endpoint, method, {
            transform: data => ({
              ...data,
              id: data.title,
            }),
          })
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        required
        label="Наименование"
        placeholder="Наименование *"
        value={form.get('data.title', '')}
        error={form.get('errors.title', '')}
        onChange={form.set('data.title')}
        onError={form.set('errors.title')}
      />
      <Input
        required
        type="multi"
        rows={5}
        label="Описание"
        placeholder="Описание *"
        value={form.get('data.description', '')}
        error={form.get('errors.description', '')}
        onChange={form.set('data.description')}
        onError={form.set('errors.description')}
      />
    </Modal>
  )
}

export default TermsEditModal
