import Button, { BUTTON_TYPES } from '@/components/Button'

import { VIEW_DICT } from '../../constants'

import styles from './index.module.css'


const Menu = ({
  active,
  onChange,
}) => (
  <div className={styles.root}>
    {Object.entries(VIEW_DICT).map(([key, label]) => (
      <Button
        key={key}
        type={key === active ? BUTTON_TYPES.fill : BUTTON_TYPES.text}
        style={key === active ? undefined : { padding: '0 25px' }}
        onClick={() => onChange(key)}
      >
        {label}
      </Button>
    ))}
  </div>
)

export default Menu
