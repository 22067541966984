import { useContext } from 'react'
import { observer } from 'mobx-react-lite'


import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import ReferenceInformation from '@/components/ReferenceInformation'
import OperationsList from '@/components/OperationsList'

const Operations = () => {
  const store = useContext(StoreContext)
  const { httpStore } = store
  const surveysEndpoint = 'surveys/'
  const dataEndpoint = getApiPath(surveysEndpoint)
  const surveysData = httpStore.fetchRequest(dataEndpoint, {}).get('data', [])

  let operations = {}

  for (let survey of surveysData) {
    if (!operations[survey.operation.id]) {
      operations[survey.operation.id] = {
        title: survey.title,
        link: survey.id,
        items: [{
          label: survey.title,
          link: survey.id,
        }],
      }
    } else {
      operations[survey.operation.id]['title'] = survey.operation.name
      operations[survey.operation.id]['link'] = survey.operation.id
      operations[survey.operation.id]['items'].push({
        label: survey.title,
        link: survey.id,
      })
    }
  }

  return (
    <>
      <OperationsList dataArray={Object.values(operations)}/>
      <ReferenceInformation />
    </>
  )
}

export default observer(Operations)
