import { types } from 'mobx-state-tree'

import { callApi, getApiPath } from '../helpers/fetch'
import { Form } from '../FormStore'


const Client = types.model('Client', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  industry: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  additional_emails: types.maybeNull(types.array(types.string)),
})

const User = types.model('User', {
  id: types.maybeNull(types.number),
  role: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  fullname: types.maybeNull(types.string),
  deactivated_at: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  verified_at: types.maybeNull(types.string),
  clients: types.maybeNull(types.array(Client)),
})

const AuthStore = types
  .model('AuthStore', {
    user: types.optional(User, {}),
    token: types.maybeNull(types.string),
    form: types.optional(Form, {}),
  })
  .actions(self => ({
    setToken(token) {
      self.token = token
    },
    setUser(user) {
      self.user = user
    },
    login(navigate) {
      self.form.submit(getApiPath('/auth/login'), 'POST', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(({ data = {} }) => {
          const token = data.token_type + ' ' + data.access_token
          return {
            token,
            user: data.user_id,
          }
        })
        .then(async({ token, user }) => {
          const headers = {
            'Authorization': token,
          }
          const resp = await callApi(getApiPath('users/' + user), { headers })
          // TODO user by id after backend fix
          return {
            user: resp.data,
            token,
          }
        })
        .then(({ token, user }) => {
          self.setToken(token)
          self.setUser(user)
          if (typeof navigate === 'function') navigate('/')
        })
        .catch(console.error)
    },
    registration() {
      // TODO
    },
    logout(navigate) {
      self.setToken(null)
      self.setUser({})
      if (typeof navigate === 'function') navigate('/')
    },
  }))

export default AuthStore
