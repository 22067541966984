import React, { memo } from 'react'
import { Handle, Position, useEdges } from 'reactflow'

const Choice = ({ data, id }) => {
  const edges = useEdges()
  const canBeSource = !edges.some(e => e.source === id)

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <div>
        {data.label}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={canBeSource}
      />
    </>
  )
}

export default memo(Choice)
