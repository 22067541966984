/* eslint-disable max-len */
import React from 'react'

const Minus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    {...props}
  >
    <path d="M15.8284 12.8284H10.1716" stroke="#314560"/>
  </svg>
)

export default Minus
