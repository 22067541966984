/* eslint-disable max-len */
import React from 'react'

const Prohibition = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx="12" cy="12" r="11.5" stroke="#D12D38"/>
    <path d="M4.28613 20.1443L20.1433 4.28711" stroke="#D12D38"/>
  </svg>
)

export default Prohibition
