import { useContext } from 'react'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import styles from './index.module.css'


const QuestionaryEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const store = useContext(StoreContext)

  const { httpStore } = store

  const questionaryId = form.get('data.id')
  const endpoint = getApiPath(questionaryId ? 'surveys/' + questionaryId : 'surveys/')
  const method = questionaryId ? 'PATCH' : 'POST'
  const hasFormError = form.hasErrors

  const dataEndpoint = getApiPath('operations/')
  const data = httpStore.fetchRequest(dataEndpoint).get('data', [])

  const operation = form.get('data.operation_id', '')

  return (
    <Modal
      title={questionaryId ? 'Редактировать анкету' : 'Создать анкету'}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: questionaryId ? 'Изменить' : 'Сохранить',
          disabled: hasFormError,
          onClick: () => form.submit(endpoint, method)
            .then(res => {
              onClose()
              if (onSuccess) onSuccess(res)
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Select
        required
        label="Операция*"
        placeholder="Не выбрано"
        options={data.map(item => ({ value: item.id, label: item.name }))}
        values={operation ? [operation] : []}
        error={form.get('errors.operation_id', '')}
        onChange={v => form.set('data.operation_id')(v[0])}
        onError={form.set('errors.operation_id')}
      />
      <Input
        required
        label="Название типа операции/анкеты*"
        placeholder="Введите название анкеты"
        value={form.get('data.title', '')}
        error={form.get('errors.title', '')}
        onChange={form.set('data.title')}
        onError={form.set('errors.title')}
      />
    </Modal>
  )
}

export default QuestionaryEditModal
