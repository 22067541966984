/* eslint-disable max-len */
import React from 'react'

const Account = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <circle cx="15.0004" cy="10.7143" r="3.21429"/>
    <path d="M8.57129 19C8.57129 16.7909 10.3622 15 12.5713 15H17.4284C19.6376 15 21.4284 16.7909 21.4284 19V21.4286H8.57129V19Z"/>
  </svg>
)

export default Account
