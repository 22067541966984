import React, { useEffect, useCallback, useState, useRef } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  isEdge,
  MarkerType,
} from 'reactflow'

import './style.css'
import FlowContextMenu from './components/FlowContextMenu'

import { getLayoutedElements, getNodesEdgesFromBlock } from './helpers'
import { NODE_TYPES } from './constants'


const TreeGraph = ({
  style,
  className,
  currentBlockIndex,
  block,
  onChange = () => {},
  onRemoveBlock = () => {},
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [menu, setMenu] = useState(null)
  const ref = useRef()
  const viewport = useRef()

  const blockId = block?.id
  const nodeBlock = nodes.find(n => n.type === 'block') || {}
  const nodeBlockId = nodeBlock.id

  useEffect(() => {
    if (viewport?.current?.fitView) {
      setTimeout(viewport.current.fitView, 100)
    }
  }, [nodeBlockId, viewport])

  useEffect(() => {
    const { nodes: bNodes, edges: bEdges } = getNodesEdgesFromBlock(block, currentBlockIndex)
    const { nodes: normalNodes, edges: normalEdges } = getLayoutedElements(bNodes, bEdges)
    setNodes(normalNodes)
    setEdges(normalEdges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId])

  useEffect(() => {
    onChange({ nodes, edges })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes.length, edges.length])

  const onContextMenu = useCallback(
    (e, obj) => {
      e.preventDefault()
      const maxMenuWidth = 250
      const maxMenuHeight = 300

      let node
      let edge

      if (obj) {
        if (isEdge(obj)) {
          edge = obj
        } else {
          node = obj
        }
      }

      const panel = ref.current

      let offsetTop = 0
      let offsetLeft = 0
      let target = panel

      while (target) {
        offsetTop += target.offsetTop || 0
        offsetLeft += target.offsetLeft || 0
        target = target.parentNode
      }

      const x = e.nativeEvent.layerX
      const y = e.nativeEvent.layerY

      let top
      let left
      let bottom
      let right

      if (x - offsetLeft > panel.clientWidth - maxMenuWidth) {
        right = panel.clientWidth - x
      } else {
        left = x
      }

      if (y - offsetTop > panel.clientHeight - maxMenuHeight) {
        bottom = panel.clientHeight - y
      } else {
        top = y
      }

      setMenu({
        node,
        edge,
        top,
        left,
        bottom,
        right,
        position: { x, y },
      })
    },
    [setMenu],
  )

  const onPaneClick = useCallback(() => setMenu(null), [setMenu])

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({
          ...params,
          type: 'smoothstep',
          markerEnd: {
            type: MarkerType.Arrow,
            width: 16,
            height: 16,
            color: '#314560',
          },
          style: {
            stroke: '#314560',
          },
        }, eds)
      ),
    [setEdges]
  )

  return (
    <ReactFlowProvider>
      <ReactFlow
        ref={ref}
        onInit={instance => { viewport.current = instance }}
        className={className}
        style={style}
        preventScrolling={false}
        nodeTypes={NODE_TYPES}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onPaneClick={onPaneClick}
        onPaneContextMenu={onContextMenu}
        onNodeContextMenu={onContextMenu}
        onEdgeContextMenu={onContextMenu}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        maxZoom={1}
      >
        <MiniMap pannable zoomable />
        <Controls showInteractive={false} />
        <FlowContextMenu onClick={onPaneClick} onRemoveBlock={onRemoveBlock} {...menu} />
      </ReactFlow>
    </ReactFlowProvider>
  )
}

export default TreeGraph
