/* eslint-disable max-len */
import React from 'react'

const PlayMini = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <path d="M20.2627 14.9995L12.368 19.5575L12.368 10.4415L20.2627 14.9995Z" fill="white"/>
  </svg>
)

export default PlayMini
