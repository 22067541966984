import Modal from '@/components/Modal'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'


const AlertModal = ({
  title,
  text,
  onClose,
  ...other
}) => {
  return (
    <Modal
      {...other}
      title={title}
      type="center"
      className={styles.root}
      titleClassName={styles.title}
      width={460}
      onClose={onClose}
    >
      <Icon className={styles.close} type={ICONS_TYPES.close} onClick={onClose} />
      {text}
    </Modal>
  )
}

export default AlertModal
