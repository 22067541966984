import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '@/store'

import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'

import styles from './index.module.css'


const TreeFooter = ({
  title,
  active,
  total,
  onChange,
  onAdd,
  onResult,
  onRemoveBlock,
}) => {
  const store = useContext(StoreContext)
  const { formsStore } = store

  return (
    <div className={styles.root}>
      <div />
      <div className={styles.blockControls}>
        <Button
          className={styles.addPrev}
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          onClick={() => {
            const formName = 'createBlock'
            formsStore.createForm(formName, {
              modalComponent: 'InputModal',
              props: {
                title: 'Создать блок',
                label: 'Наименование',
                placeholder: 'Введите наименование *',
                required: true,
                onSuccess: v => onAdd(active, v),
              },
            })
          }}
        >
          +
        </Button>
        <Button
          className={styles.prev}
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          disabled={active === 0}
          onClick={() => onChange(active - 1)}
        >
          Предыдущий
        </Button>
        <Button
          className={styles.delete}
          color={BUTTON_COLORS.blue}
          onClick={onRemoveBlock}
        >
          <div className={styles.minus}>-</div>
          <div className={styles.text}>Блок {active + 1}. {title}</div>
        </Button>
        <Button
          className={styles.next}
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          disabled={active === total - 1}
          onClick={() => onChange(active + 1)}
        >
          Следующий
        </Button>
        <Button
          className={styles.addNext}
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          onClick={() => {
            const formName = 'createBlock'
            formsStore.createForm(formName, {
              modalComponent: 'InputModal',
              props: {
                title: 'Создать блок',
                label: 'Наименование',
                placeholder: 'Введите наименование *',
                required: true,
                onSuccess: v => onAdd(active + 1, v),
              },
            })
          }}
        >
          +
        </Button>
      </div>
      <div className={styles.right}>
        <Button
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          onClick={onResult}
        >
          Создать результаты
        </Button>
      </div>
    </div>
  )
}

export default observer(TreeFooter)
