import { useCallback, useContext, useState } from 'react'
import { StoreContext } from '@/store'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import { getApiPath } from '../../store/helpers/fetch'

import styles from './index.module.css'


const types = [
  {
    value: 'template',
    label: 'По шаблону',
  },
  {
    value: 'country',
    label: 'Страны',
  },
  {
    value: 'custom',
    label: 'Ручной ввод',
  },
]

const TreeQuestionEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const store = useContext(StoreContext)
  const [search, setSearch] = useState()

  const { httpStore } = store
  const params = {
    limit: 1000,
  }

  if (search) {
    params['text__ilike'] = search
  }
  const dataEndpoint = getApiPath('question-templates/')
  const data = httpStore.fetchRequest(dataEndpoint, { params }).get('data', [])

  const choicesLength = form.get('data.choices.length', 0)
  const qType = form.get('data.type')
  const qTemplate = form.get('data.template')
  const hasFormError = form.hasErrors || choicesLength === 0

  const { insertToArray, removeFromArray } = form
  const addChoice = useCallback(() => {
    insertToArray('data.choices', choicesLength, '')
  }, [insertToArray, choicesLength])
  const rmChoice = useCallback(index => () => {
    removeFromArray('data.choices', index)
  }, [removeFromArray])

  return (
    <Modal
      title={'Добавить вопрос'}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: 'Сохранить',
          disabled: hasFormError,
          onClick: () => {
            onSuccess(form.get('data'))
            onClose()
          },
        },
      ]}
      {...other}
    >
      <Select
        required
        label="Тип вопроса"
        placeholder="Не выбрано"
        options={types}
        values={qType ? [qType] : []}
        error={form.get('errors.type', '')}
        onChange={v => {
          form.set('data.template')()
          if (v[0] === 'country') {
            form.set('data.text')('Страна')
            form.set('data.choices')([
              { label: 'РФ', value: 'r' },
              { label: 'Дружественная', value: 'f' },
              { label: 'Не дружественная', value: 'n' },
            ])
          } else {
            form.set('data.text')()
            form.set('data.choices')([])
          }
          form.set('data.type')(v[0])
        }}
        onError={form.set('errors.type')}
      />
      {qType === 'template' && (
        <Select
          required
          label="Шаблон вопроса"
          placeholder="Не выбрано"
          onSearch={setSearch}
          options={data.map(q => ({ value: q.id, label: q.text }))}
          values={qTemplate ? [qTemplate] : []}
          error={form.get('errors.template', '')}
          onChange={v => {
            const q = data.find(item => item.id === v[0])
            if (q) {
              form.set('data.template')(q.id)
              form.set('data.text')(q.text)
              form.set('data.choices')(q.choices.map(c => ({ label: c })))
            }
          }}
          onError={form.set('errors.template')}
        />
      )}
      {(qType === 'custom' || qType === 'country' || (qTemplate)) && (
        <>
          <Input
            required
            label="Вопрос"
            placeholder="Наименование *"
            value={form.get('data.text', '')}
            error={form.get('errors.text', '')}
            onChange={form.set('data.text')}
            onError={form.set('errors.text')}
          />
          {Array(choicesLength).fill(0).map((item, i) => (
            <Input
              key={i}
              required
              disabled={qType === 'country'}
              label={`Ответ ${i + 1}`}
              placeholder="Вариант ответа *"
              value={form.get(`data.choices.${i}.label`, '')}
              error={form.get(`errors.choices.${i}.label`, '')}
              onChange={form.set(`data.choices.${i}.label`)}
              onError={form.set(`errors.choices.${i}.label`)}
            >
              {qType !== 'country' && (
                <Icon
                  className={styles.clear}
                  type={ICONS_TYPES.close}
                  onClick={rmChoice(i)}
                />
              )}
            </Input>
          ))}
          {qType !== 'country' && (
            <Button
              type={BUTTON_TYPES.dashed}
              color={BUTTON_COLORS.blue}
              onClick={addChoice}
            >
              + Добавить ответ
            </Button>
          )}
        </>
      )}
    </Modal>
  )
}

export default TreeQuestionEditModal
