/* eslint-disable max-len */
import React from 'react'

const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    {...props}
  >
    <path d="M13 9.99999V16"/>
    <path d="M15.8284 12.8284H10.1716"/>
  </svg>
)

export default Plus
