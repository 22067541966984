import { useCallback } from 'react'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import { getApiPath } from '../../store/helpers/fetch'

import styles from './index.module.css'


const QuestionEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const questionId = form.get('data.id')
  const endpoint = getApiPath(questionId ? 'question-templates/' + questionId : 'question-templates/')
  const method = questionId ? 'PATCH' : 'POST'

  const choicesLength = form.get('data.choices.length', 0)

  const hasFormError = form.hasErrors || choicesLength < 2

  const { insertToArray, removeFromArray } = form
  const addChoice = useCallback(() => {
    insertToArray('data.choices', choicesLength, '')
  }, [insertToArray, choicesLength])
  const rmChoice = useCallback(index => () => {
    removeFromArray('data.choices', index)
  }, [removeFromArray])

  return (
    <Modal
      title={questionId ? 'Редактировать вопрос' : 'Создать вопрос'}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: questionId ? 'Изменить' : 'Сохранить',
          disabled: hasFormError,
          onClick: () => form.submit(endpoint, method)
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        required
        label="Вопрос"
        placeholder="Наименование *"
        value={form.get('data.text', '')}
        error={form.get('errors.text', '')}
        onChange={form.set('data.text')}
        onError={form.set('errors.text')}
      />
      {Array(choicesLength).fill(0).map((item, i) => (
        <Input
          required
          key={i}
          label={`Ответ ${i + 1}`}
          placeholder="Вариант ответа *"
          value={form.get(`data.choices.${i}`, '')}
          error={form.get(`errors.choices.${i}`, '')}
          onChange={form.set(`data.choices.${i}`)}
          onError={form.set(`errors.choices.${i}`)}
        >
          <Icon
            className={styles.clear}
            type={ICONS_TYPES.close}
            onClick={rmChoice(i)}
          />
        </Input>
      ))}
      <Button
        type={BUTTON_TYPES.dashed}
        color={BUTTON_COLORS.blue}
        onClick={addChoice}
      >
        + Добавить ответ
      </Button>
    </Modal>
  )
}

export default QuestionEditModal
