/* eslint-disable max-len */
import React from 'react'

const Exclamation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <path d="M15 17V7" stroke="white" strokeWidth="2"/>
    <path d="M15 19V21" stroke="white" strokeWidth="2"/>
  </svg>
)

export default Exclamation
