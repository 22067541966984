/* eslint-disable max-len */
import React from 'react'

const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    viewBox="0 0 24 20"
    {...props}
  >
    <path d="M12 1V11M12 1L10 4M12 1L14 4" stroke="#314560" strokeWidth="1.5" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23.4824 19.9709C23.7824 19.8834 24.0016 19.6063 24.0016 19.278V11.077C24.0016 10.6904 23.6882 10.377 23.3016 10.377C22.915 10.377 22.6016 10.6904 22.6016 11.077V18.6L1.55234 18.6L1.55234 11.0772C1.55234 10.6906 1.23894 10.3772 0.852344 10.3772C0.465745 10.3772 0.152344 10.6906 0.152344 11.0772V19.5816C0.152344 19.7824 0.293762 19.9501 0.482422 19.9907V20H0.570725H1.55234L23.4824 20V19.9709Z" fill="#314560"/>
  </svg>
)

export default Download
