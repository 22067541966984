import Question from './components/Question'
import Choice from './components/Choice'
import Block from './components/Block'

export const NODE_TYPES = {
  question: Question,
  choice: Choice,
  block: Block,
}

export const NODE_DICT = {
  question: 'вопрос',
  choice: 'ответ',
  block: 'блок',
}
