import { Navigate } from 'react-router'
import {
  Root,

  Login,

  Admin,
  ClientsMgmt,
  UsersMgmt,
  QuestionsMgmt,
  OperationsMgmt,
  QuestionariesMgmt,
  QuestionaryMgmt,
  CountriesMgmt,
  TermsMgmt,

  Operations,
  Operation,
  Countries,
  Profile,

  NotFound,
} from './pages'

import RouteWrapper from './components/RouteWrapper'

import { LAYOUT_TYPES } from '@/constants'

const routes = [{
  path: '/',
  element: <Root/>,
  children: [
    {
      path: 'login',
      element: <Login/>,
      available: authStore => !authStore.token,
      layoutProps: {
        type: LAYOUT_TYPES.auth,
      },
    },
    {
      path: 'admin',
      element: <Admin/>,
      available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      children: [
        {
          path: 'users',
          element: <UsersMgmt/>,
          available: authStore => authStore.user.role === 'admin',
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'clients',
          element: <ClientsMgmt/>,
          available: authStore => authStore.user.role === 'admin',
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'questions',
          element: <QuestionsMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'operations',
          element: <OperationsMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'questionaries',
          element: <QuestionariesMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'questionaries/:id',
          element: <QuestionaryMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.quiz,
          },
        },
        {
          path: 'countries',
          element: <CountriesMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
        {
          path: 'terms',
          element: <TermsMgmt/>,
          available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
          layoutProps: {
            type: LAYOUT_TYPES.user,
          },
        },
      ],
    },
    {
      path: 'operations',
      element: <Operations/>,
      available: authStore => !!authStore.token,
      layoutProps: {
        type: LAYOUT_TYPES.operation,
      },
    },
    {
      path: 'operations/:id',
      element: <Operation/>,
      available: authStore => !!authStore.token,
      layoutProps: {
        type: LAYOUT_TYPES.operation,
      },
    },
    {
      path: 'countries',
      element: <Countries/>,
      available: authStore => !!authStore.token,
      layoutProps: {
        type: LAYOUT_TYPES.main,
      },
    },
    {
      path: 'profile',
      element: <Profile/>,
      available: authStore => !!authStore.token,
      layoutProps: {
        type: LAYOUT_TYPES.profile,
      },
    },
  ],
}]

const filterRoutes = authStore => (memo, curr) => {
  const availableFunc = curr.available || (() => true)
  const available = availableFunc(authStore)
  if (!available) return memo
  const route = !curr.layoutProps ? curr : {
    ...curr,
    element: <RouteWrapper {...curr.layoutProps}>{curr.element}</RouteWrapper>,
  }
  if (!route.children) {
    return [...memo, route]
  } else {
    const children = route.children.reduce(filterRoutes(authStore), [])
    let redirect = children[0]?.path || ''
    redirect = /[:?*]/.test(redirect) ? '' : redirect
    if (redirect) {
      children.push({
        index: true,
        element: <Navigate to={redirect} replace/>,
      })
    }
    children.push({
      path: '*',
      element: <NotFound/>,
    })
    return [
      ...memo,
      {
        ...route,
        children,
      },
    ]
  }
}

const getRoutes = (authStore) => {
  return routes.reduce(filterRoutes(authStore), [])
}

export default getRoutes
