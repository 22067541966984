import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const statuses = [
  { value: 'allow', label: 'Разрешено' },
  { value: 'commission', label: 'Правкомиссия' },
  { value: 'deny', label: 'Запрещено' },
]

const ResultEditModal = ({
  title,
  form,
  onSuccess,
  onClose,
}) => {
  const hasFormError = form.hasErrors
  const status = form.get('data.status', '')

  return (
    <Modal
      title={title}
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: 'Сохранить',
          disabled: hasFormError,
          onClick: () => {
            onSuccess(form.get('data', {}))
            onClose()
          },
        },
      ]}
    >
      <Input
        label="Наименование"
        placeholder="Введите наименование*"
        required
        type="multi"
        rows={5}
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Select
        required
        label="Статус"
        placeholder="Не выбрано"
        options={statuses}
        values={status ? [status] : []}
        error={form.get('errors.status', '')}
        onChange={v => form.set('data.status')(v[0])}
        onError={form.set('errors.status')}
      />
      <Input
        label="Применимое регулирование"
        placeholder="https://demo.com|Alias"
        type="multi"
        rows={5}
        value={form.get('data.regulation', '')}
        error={form.get('errors.regulation', '')}
        onChange={form.set('data.regulation')}
        onError={form.set('errors.regulation')}
      />
      <Input
        label="Комментарии"
        placeholder="Введите комментарии*"
        required
        type="multi"
        rows={5}
        value={form.get('data.text', '')}
        error={form.get('errors.text', '')}
        onChange={form.set('data.text')}
        onError={form.set('errors.text')}
      />
    </Modal>
  )
}

export default ResultEditModal
