import dagre from 'dagre'
import { MarkerType } from 'reactflow'
import { v4 as uuid } from 'uuid'


const getNodeSize = ({
  type = 'default',
  data: {
    label,
  } = {},
}) => {
  const shadow = document.createElement('div')
  shadow.className = 'react-flow shadow'
  const node = document.createElement('div')
  node.className = 'react-flow__node react-flow__node-' + type
  const nodeContent = document.createElement('div')
  nodeContent.innerText = label
  node.append(nodeContent)
  shadow.append(node)
  document.body.append(shadow)
  const width = node.offsetWidth
  const height = node.offsetHeight
  shadow.remove()
  return { width, height }
}

const getQuestion = (id, questionary) => {
  const nodes = []
  const edges = []

  const question = questionary[id]

  if (!question) {
    return { nodes, edges }
  }

  const { choices = [] } = question

  nodes.push({
    id: question.id,
    type: 'question',
    data: {
      label: question.text,
      type: question.question_type,
    },
  })

  choices.forEach(c => {
    edges.push({
      id: uuid(),
      source: question.id,
      target: c,
      type: 'smoothstep',
      markerEnd: {
        type: MarkerType.Arrow,
        width: 16,
        height: 16,
        color: '#314560',
      },
      style: {
        stroke: '#314560',
      },
    })

    const choice = questionary[c]
    if (choice) {
      nodes.push({
        id: c,
        type: 'choice',
        data: {
          label: choice.text,
          value: choice.value,
        },
        parentNode: question.id,
      })

      if (choice.next_id) {
        edges.push({
          id: uuid(),
          source: c,
          target: choice.next_id,
          type: 'smoothstep',
          markerEnd: {
            type: MarkerType.Arrow,
            width: 16,
            height: 16,
            color: '#314560',
          },
          style: {
            stroke: '#314560',
          },
        })
      }
    }
  })

  return { nodes, edges }
}

export const getNodesEdgesFromBlock = (block, currentBlockIndex) => {
  const nodes = []
  const edges = []

  const { questionary } = block

  nodes.push({
    id: block.id,
    type: 'block',
    data: {
      label: block.text,
      index: currentBlockIndex,
    },
  })

  if (block.next_id) {
    edges.push({
      id: uuid(),
      source: block.id,
      target: block.next_id,
      type: 'smoothstep',
      markerEnd: {
        type: MarkerType.Arrow,
        width: 16,
        height: 16,
        color: '#314560',
      },
      style: {
        stroke: '#314560',
      },
    })
  }

  Object.entries(questionary)
    .filter(([, val]) => val.type === 'question')
    .forEach(([id]) => {
      const { nodes: bNodes, edges: bEdges } = getQuestion(id, questionary)
      nodes.push(...bNodes)
      edges.push(...bEdges)
    })

  return { nodes, edges }
}

export const getLayoutedElements = (nodes, edges, dx = 0, dy = 0) => {
  const nodesep = 20
  const ranksep = 50
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ nodesep, ranksep })

  const questions = nodes.filter(n => n.type === 'question')
  const questionId = questions.length === 1 ? questions[0].id : undefined

  nodes.forEach((node) => {
    const size = getNodeSize(node)
    dagreGraph.setNode(node.id, size)
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = 'top'
    node.sourcePosition = 'bottom'

    const question = questionId ? dagreGraph.node(questionId) : undefined
    const { x: qx, y: qy } = question ? question : { x: 0, y: 0 }

    node.position = {
      x: nodeWithPosition.x - (nodeWithPosition.width / 2) + dx - qx,
      y: nodeWithPosition.y - (nodeWithPosition.height / 2) + dy - qy,
    }

    if (node.parentNode) {
      const parent = dagreGraph.node(node.parentNode)
      node.position = {
        x: node.position.x - parent.x + (parent.width / 2) - dx + qx,
        y: node.position.y - parent.y + (parent.height / 2) - dy + qy,
      }
    }

    return node
  })

  return { nodes, edges }
}
