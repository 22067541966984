/* eslint-disable max-len */
import React from 'react'

const Norms = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={28}
    viewBox="0 0 22 28"
    {...props}
  >
    <path d="M0.5 0.5H16.7799L21.5 4.36194V27H0.5V0.5Z" stroke="#D12D38"/>
    <path d="M3.66602 11.918H18.3327" stroke="#D12D38"/>
    <path d="M3.66602 15.125H18.3327" stroke="#D12D38"/>
    <path d="M3.66602 18.793H9.16602" stroke="#D12D38"/>
    <path d="M3.66602 22H9.16602" stroke="#D12D38"/>
    <path d="M12.834 22H18.334" stroke="#D12D38"/>
    <path d="M16.959 0.457031V4.58203H21.5423" stroke="#D12D38"/>
  </svg>
)

export default Norms
