import classNames from 'classnames'

import styles from './index.module.css'

const Loader = ({ className }) => {

  return (
    <div className={classNames(
      styles.root,
      className
    )}>
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
