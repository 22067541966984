export const NAV_ITEMS = [
  {
    label: 'Пользователи',
    available: authStore => authStore.user.role === 'admin',
    children: [
      {
        label: 'Сотрудники',
        link: '/admin/users',
        available: authStore => authStore.user.role === 'admin',
      },
      {
        label: 'Клиенты',
        link: '/admin/clients',
        available: authStore => authStore.user.role === 'admin',
      },
    ],
  },
  {
    label: 'Опросы',
    available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
    children: [
      {
        label: 'Операции',
        link: '/admin/operations',
        available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      },
      {
        label: 'Вопросы',
        link: '/admin/questions',
        available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      },
      {
        label: 'Анкеты',
        link: '/admin/questionaries',
        available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      },
    ],
  },
  {
    label: 'Справочная информация',
    available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
    children: [
      {
        label: 'Государства',
        link: '/admin/countries',
        available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      },
      {
        label: 'Термины',
        link: '/admin/terms',
        available: authStore => ['admin', 'lawyer'].includes(authStore.user.role),
      },
    ],
  },
  // {
  //   label: 'Аналитика',
  //   link: '#',
  // },
]
