import { useState, useContext, useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import { transformQuestionsToBlocks, checkNodeLinks, getResults } from '@/utils/helpers/questionary'

import Menu from './components/Menu'
import Header from './components/Header'
import EmptyBlocks from './components/EmptyBlocks'
import TreeFooter from './components/TreeFooter'
import ListView from './components/ListView'
import ResultsTable from './components/ResultsTable'
import TreeGraph from '@/components/TreeGraph'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'

import { transformNodesEdgesToBlock, VIEW_TYPES } from './constants'


const Questionary = () => {
  const store = useContext(StoreContext)
  const params = useParams()
  const [activeTab, setActiveTabInner] = useState(VIEW_TYPES.tree)
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)

  const { httpStore, formsStore } = store

  const dataEndpoint = getApiPath('surveys/' + params.id)

  const formName = 'editQuestionary' + params.id
  const formObject = formsStore.getForm(formName)

  httpStore.fetchRequest(dataEndpoint, {
    cacheTime: Number.MAX_SAFE_INTEGER,
    onSuccess: res => {
      const data = res.get('data')
      if (data) {
        formsStore.createForm(formName, {
          form: {
            data: {
              ...data,
              operation_id: data.operation?.id,
              blocks: transformQuestionsToBlocks(data.questions),
              results: data.questions.filter(q => q.type === 'result'),
            },
          },
        })
      }
    },
  })

  const form = formObject?.form

  const blocks = form?.get('data.blocks', [])
  const results = form?.get('data.results', [])

  const setActiveTab = useCallback(tab => {
    if (tab !== VIEW_TYPES.tree) {
      const errorBlock = checkNodeLinks(blocks)
      if (errorBlock !== undefined) {
        setCurrentBlockIndex(errorBlock)
        formsStore.createForm('alertEdges', {
          modalComponent: 'AlertModal',
          props: {
            title: 'Ошибка',
            text: 'Анкета содержит несвязные вопросы. ' +
              'Проверьте правильность составления анкеты и повторите попытку.',
          },
        })
      } else if (tab === VIEW_TYPES.result) {
        formsStore.createForm('confirmResult', {
          modalComponent: 'ConfirmModal',
          props: {
            title: 'Результаты',
            text: 'Вы переходите к созданию результатов. ' +
              'На основе ваших вопросов и ответов будет создана таблица со всеми вариантами завершения анкеты. ' +
              'Рекомендуем сначала закончить с редактированием дерева анкеты. ' +
              'При изменении анкеты после создания таблицы может измениться количество строк ' +
              '– и введенные вами результаты могут быть утеряны.',
            onSuccess: () => setActiveTabInner(tab),
          },
        })
      } else {
        setActiveTabInner(tab)
      }
    } else {
      setActiveTabInner(tab)
    }
  }, [blocks, formsStore])

  useEffect(() => {
    if (activeTab === VIEW_TYPES.result) {
      const newResults = getResults(blocks, results)
      form.set('data.results')(newResults)
    }
  }, [activeTab, blocks, form, results])

  const createBlock = useCallback((index, text) => {
    form.insertToArray('data.blocks', index, {
      id: uuid(),
      type: 'block',
      text,
      questionary: {},
    })
    setCurrentBlockIndex(index)
    setActiveTab(VIEW_TYPES.tree)
  }, [form, setActiveTab])

  const removeBlock = useCallback(() => {
    formsStore.createForm('confirmBlockRm', {
      modalComponent: 'ConfirmModal',
      props: {
        title: 'Удалить блок?',
        text: 'Вы собираетесь удалить блок. ' +
          'Вместе с ним будут удалены все вопросы и ответы, которые вы создали в этом блоке. ' +
          'Если вы уверены в своем действии, то нажмите кнопку “Подтвердить”.',
        onSuccess: () => form.removeFromArray('data.blocks', currentBlockIndex),
      },
    })
  }, [currentBlockIndex, form, formsStore])

  if (!formObject) {
    return null
  }

  const currentBlock = blocks[currentBlockIndex]

  return (
    <div className={classNames(pageStyles.page, styles.root)}>
      <Menu active={activeTab} onChange={setActiveTab} />
      <Header form={form} />
      {!!blocks.length && activeTab === VIEW_TYPES.tree && (
        <>
          <TreeGraph
            className={styles.tree}
            block={currentBlock}
            currentBlockIndex={currentBlockIndex}
            onRemoveBlock={removeBlock}
            onChange={v => {
              const block = transformNodesEdgesToBlock(v)
              if (block) {
                form.set('data.blocks.' + currentBlockIndex)(block)
              }
            }}
          />
          <TreeFooter
            title={currentBlock.text}
            active={currentBlockIndex}
            total={blocks.length}
            onChange={setCurrentBlockIndex}
            onAdd={createBlock}
            onResult={() => setActiveTab(VIEW_TYPES.result)}
            onRemoveBlock={removeBlock}
          />
        </>
      )}
      {!blocks.length && (
        <EmptyBlocks onCreate={createBlock}/>
      )}
      {activeTab === VIEW_TYPES.result && (
        <ResultsTable formName={formName} />
      )}
      {activeTab === VIEW_TYPES.list && (
        <ListView blocks={blocks} />
      )}
    </div>
  )
}

export default observer(Questionary)
