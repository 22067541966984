import { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import { nowDate, formatDate } from '@/utils/helpers/date'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'
import DropDownList from '@/components/DropDownList'
import RadioButtonGroup, { RB_TYPES, RB_COLORS } from '@/components/RadioButtonGroup'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'

const getStatus = item => {
  if (item.deactivated_at) {
    return (
      <div className={styles.blocked}>
        <span>Заблокирован</span>
        <Icon type={ICONS_TYPES.blocked} />
      </div>
    )
  }
  if (item.verified_at) return 'Активен'
  return 'Выслано приглашение'
}

const Clients = () => {
  const store = useContext(StoreContext)

  const { httpStore, formsStore } = store

  const [statusFilter, setStatusFilter] = useState()

  const params = {
    limit: 1000,
    'role': 'client',
  }

  if (statusFilter === 'blocked') {
    params['deactivated_at__isnull'] = false
  } else if (statusFilter === 'active') {
    params['deactivated_at__isnull'] = true
    params['verified_at__isnull'] = false
  }

  const usersEndpoint = 'users/'
  const dataEndpoint = getApiPath(usersEndpoint)
  const data = httpStore.fetchRequest(dataEndpoint, { params }).get('data', [])

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Управление клиентами
        </h1>
        <div className={pageStyles.headerControls}>
          <RadioButtonGroup
            type={RB_TYPES.check}
            color={RB_COLORS.blue}
            items={[
              { id: 'blocked', label: 'Заблокированные' },
              { id: 'active', label: 'Активные' },
            ]}
            current={statusFilter}
            onChange={setStatusFilter}
          />
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createClient'
              formsStore.createForm(formName, {
                modalComponent: 'ClientEditModal',
                props: {
                  onSuccess: () => httpStore.fetchRequest(dataEndpoint, {
                    params,
                    cacheTime: 0,
                  }),
                },
              })
            }}
          >
            <span>+ Создать клиента</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={data}
          head={[
            {
              title: 'Имя',
              className: styles.big,
            },
            {
              title: 'Логин',
              className: styles.big,
            },
            {
              title: 'Компания',
              className: styles.small,
            },
            {
              title: 'Дата активации',
              className: styles.date,
            },
            {
              title: 'Статус',
              className: styles.small,
            },
            {
              className: styles.dotMenu,
            },
          ]}
          body={[
            {
              model: item => item.fullname,
            },
            {
              model: item => item.username,
            },
            {
              model: item => item.clients[0]?.title || '-',
            },
            {
              model: item => formatDate(item.verified_at),
            },
            {
              model: getStatus,
            },
            {
              className: styles.dotMenu,
              model: item => (
                <DropDownList
                  hasBackground={false}
                  hasBorder
                  sideToOpen="left"
                  HeadComponent={({ isActive }) => (
                    <Icon
                      className={isActive && styles.dotMenuActive}
                      type={ICONS_TYPES.dotMenu}
                    />
                  )}
                >
                  <Button
                    type={BUTTON_TYPES.text}
                    color={BUTTON_COLORS.blue}
                    onClick={() => {
                      const formName = 'editClient' + item.id
                      formsStore.createForm(formName, {
                        modalComponent: 'ClientEditModal',
                        form: {
                          data: {
                            ...item,
                            client: (item.clients || [])[0],
                          },
                        },
                        props: {
                          onSuccess: () => httpStore.fetchRequest(dataEndpoint, {
                            params,
                            cacheTime: 0,
                          }),
                        },
                      })
                    }}
                  >
                    Редактировать
                  </Button>
                  <Button
                    type={BUTTON_TYPES.text}
                    color={!!item.deactivated_at ? BUTTON_COLORS.blue : BUTTON_COLORS.red}
                    onClick={() => {
                      const url = getApiPath(usersEndpoint + item.id)
                      formsStore.sendQuickForm(
                        {
                          form: {
                            data: {
                              deactivated_at: !!item.deactivated_at ? null : nowDate(),
                            },
                          },
                        },
                        url,
                        'PATCH'
                      )
                        .then(() => {
                          httpStore.fetchRequest(dataEndpoint, {
                            params,
                            cacheTime: 0,
                          })
                        })
                    }}
                  >
                    {!!item.deactivated_at ? 'Разблокировать' : 'Заблокировать'}
                  </Button>
                </DropDownList>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Clients)
