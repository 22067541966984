const Blocked = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    {...props}
  >
    <path d="M1 23V23C1 17.4772 5.47715 13 11 13V13C16.5228 13 21 17.4772 21 23V23" fill="none" />
    <circle cx="11" cy="6" r="5" fill="none" />
    <circle cx="20" cy="19" r="6" />
    <path d="M23 16L17 22" stroke="white"/>
    <path d="M17 16L23 22" stroke="white"/>
  </svg>
)

export default Blocked
