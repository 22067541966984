/* eslint-disable max-len */
import React from 'react'

const Telegram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    viewBox="0 0 14 12"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M13.8407 1.03765C13.6494 3.1473 12.8179 8.26272 12.397 10.6257C12.2185 11.6259 11.8665 11.9593 11.5247 11.9939C10.785 12.066 10.2213 11.4819 9.5046 10.9911C8.38232 10.223 7.74721 9.74294 6.65808 8.99083C5.39806 8.12403 6.21427 7.64663 6.93355 6.86785C7.1223 6.66515 10.3846 3.56069 10.4483 3.27798C10.456 3.24331 10.4636 3.11262 10.3897 3.04328C10.3157 2.97394 10.206 2.99794 10.1244 3.01661C10.0122 3.04328 8.21908 4.28346 4.7451 6.73449C4.23497 7.09988 3.77585 7.27857 3.36265 7.26791C2.90608 7.25724 2.03121 6.99853 1.3808 6.77717C0.582449 6.50513 -0.0526615 6.36111 0.0034527 5.90237C0.0315098 5.66234 0.347789 5.41697 0.952292 5.16626C4.67113 3.47268 7.15035 2.35518 8.38997 1.81643C11.9328 0.27487 12.6674 0.00816335 13.1469 0.000162159C13.2515 -0.0025049 13.4887 0.0268325 13.6417 0.154851C13.7718 0.264201 13.8075 0.410889 13.8228 0.514905C13.8356 0.616253 13.856 0.853622 13.8407 1.03765Z" fill="white"/>
  </svg>
)

export default Telegram
