import { types, getSnapshot } from 'mobx-state-tree'
import { createContext } from 'react'

import { getStoreInitData, setStoreInitData } from './constants'

import LayoutStore from './LayoutStore'
import AuthStore from './AuthStore'
import FormStore from './FormStore'
import HttpStore from './HttpStore'


let store

const Store = types
  .model({
    layoutStore: types.optional(LayoutStore, {}),
    authStore: types.optional(AuthStore, {}),
    formsStore: types.optional(FormStore, {}),
    httpStore: types.optional(HttpStore, {}),
  })

export const getStore = () => {
  if (!store) {
    const initData = getStoreInitData()
    store = Store.create(initData)

    window.addEventListener('beforeunload', () => {
      const authStore = getSnapshot(store.authStore)
      setStoreInitData({
        authStore: { ...authStore, form: undefined },
      })
    })
  }

  return store
}

export const StoreContext = createContext({})
