import { useState, useCallback } from 'react'
import classNames from 'classnames'

import Icon, { ICONS_TYPES, ROTATE_TYPES } from '@/components/Icon'

import Question from '../Question'

import styles from '../index.module.css'


const Block = ({ block }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => setOpen(!open), [open])

  return (
    <div className={classNames(styles.block, open && styles.open)}>
      <div className={styles.title} onClick={toggleOpen}>
        <span>{block.text}</span>
        <Icon
          className={styles.arrow}
          rotate={open ? ROTATE_TYPES.down : ROTATE_TYPES.up}
          type={ICONS_TYPES.arrowDown}
          size={14}
        />
      </div>
      {open && (
        <Question block={block} questionId={block.next_id} />
      )}
    </div>
  )
}

export default Block
