/* eslint-disable max-len */
import React from 'react'

const SendEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={19}
    viewBox="0 0 26 19"
    {...props}
  >
    <path d="M1.34766 1.5L12.2533 9.56067C12.6065 9.82177 13.0888 9.82177 13.442 9.56067L24.3477 1.5" strokeWidth="1.4" strokeLinecap="round"/>
    <path d="M24.8477 5V2C24.8477 1.44772 24.3999 1 23.8477 1H1.84766C1.29537 1 0.847656 1.44772 0.847656 2V17C0.847656 17.5523 1.29537 18 1.84766 18H13.3477" strokeWidth="1.4" strokeLinecap="round"/>
    <path d="M21.8477 9V18M21.8477 9L19.8477 12M21.8477 9L23.8477 12" strokeWidth="1.4" strokeLinecap="round"/>
  </svg>
)

export default SendEmail
