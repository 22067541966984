import { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'
import { formatDate } from '@/utils/helpers/date'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Icon, { ICONS_TYPES } from '@/components/Icon'
import DropDownList from '@/components/DropDownList'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const Questions = () => {
  const store = useContext(StoreContext)

  const { httpStore, formsStore } = store

  const params = {
    limit: 1000,
  }

  const dataEndpoint = getApiPath('question-templates/')
  const data = httpStore.fetchRequest(dataEndpoint, { params }).get('data', [])

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Управление справочником вопросов
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createQuestion'
              formsStore.createForm(formName, {
                modalComponent: 'QuestionEditModal',
                props: {
                  onSuccess: () => httpStore.fetchRequest(dataEndpoint, {
                    params,
                    cacheTime: 0,
                  }),
                },
              })
            }}
          >
            <span>+ Создать вопрос</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={data}
          head={[
            {
              className: styles.name,
              title: 'Наименование',
            },
            {
              className: styles.date,
              title: 'Дата создания',
            },
            {
              className: styles.count,
              title: 'Колличество ответов',
            },
            {
              className: styles.dotMenu,
            },
          ]}
          body={[
            {
              className: styles.name,
              model: item => item.text,
            },
            {
              className: styles.date,
              model: item => formatDate(item.created_at),
            },
            {
              className: styles.count,
              model: item => item?.choices?.length,
            },
            {
              className: styles.dotMenu,
              model: item => (
                <DropDownList
                  hasBackground={false}
                  hasBorder
                  sideToOpen="left"
                  HeadComponent={({ isActive }) => (
                    <Icon
                      className={isActive && styles.dotMenuActive}
                      type={ICONS_TYPES.dotMenu}
                    />
                  )}
                >
                  <Button
                    className={styles.edit}
                    type={BUTTON_TYPES.text}
                    onClick={() => {
                      const formName = 'createQuestion'
                      formsStore.createForm(formName, {
                        modalComponent: 'QuestionEditModal',
                        form: {
                          data: item,
                        },
                        props: {
                          onSuccess: () => httpStore.fetchRequest(dataEndpoint, {
                            params,
                            cacheTime: 0,
                          }),
                        },
                      })
                    }}
                  >
                    Редактировать
                  </Button>
                </DropDownList>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Questions)
