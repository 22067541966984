import React, { useCallback, useContext } from 'react'
import { MarkerType, useReactFlow } from 'reactflow'
import { v4 as uuid } from 'uuid'

import { StoreContext } from '@/store'

import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'

import { getLayoutedElements } from '../../helpers'
import { NODE_DICT } from '../../constants'
import styles from '../../index.module.css'


const FlowContextMenu = ({
  node,
  edge,
  top,
  left,
  bottom,
  right,
  position,
  onRemoveBlock,
  ...props
}) => {
  const store = useContext(StoreContext)
  const { formsStore } = store

  const { addNodes, addEdges, getViewport, getNodes, getNode, setNodes, getEdges, setEdges, fitView } = useReactFlow()

  const addQuestion = useCallback((q, p) => {
    const { text, type, template, choices } = q

    let dx
    let dy
    if (node) {
      dx = node.positionAbsolute.x + (node.width / 2)
      dy = node.positionAbsolute.y + (node.height / 2)
    } else {
      const { x, y, zoom } = getViewport()
      const koef = 1 / zoom
      dx = (p.x - x) * koef
      dy = (p.y - y) * koef
    }
    let sy = 0

    const questionNode = uuid()
    const normalNodes = []
    const normalEdges = []

    normalNodes.push({
      id: questionNode,
      type: 'question',
      data: {
        label: text,
        type,
        template,
      },
    })

    if (questionNode && node?.id && (node?.type === 'choice' || node?.type === 'block')) {
      sy = 100
      normalEdges.push({
        id: uuid(),
        source: node?.id,
        target: questionNode,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.Arrow,
          width: 16,
          height: 16,
          color: '#314560',
        },
        style: {
          stroke: '#314560',
        },
      })
    }

    choices.forEach(c => {
      const id = uuid()

      normalNodes.push({
        id,
        type: 'choice',
        parentNode: questionNode,
        data: {
          ...c,
        },
      })

      normalEdges.push({
        id: uuid(),
        source: questionNode,
        target: id,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.Arrow,
          width: 16,
          height: 16,
          color: '#314560',
        },
        style: {
          stroke: '#314560',
        },
      })
    })

    const { nodes, edges } = getLayoutedElements(normalNodes, normalEdges, dx, dy + sy)

    if (node) {
      setNodes(
        currentNodes => [...currentNodes.map(n => {
          if (node.id === n.id) {
            return { ...n, data: { ...n.data, lastUpdate: new Date() } }
          }
          return n
        }), ...nodes],
      )
    } else {
      addNodes(nodes)
    }
    addEdges(edges)
  }, [
    setNodes,
    addNodes,
    addEdges,
    getViewport,
    node,
  ])

  const normalize = useCallback(() => {
    const { nodes } = getLayoutedElements(getNodes(), getEdges())
    setNodes(nodes)
    setTimeout(fitView, 100)
  }, [getNodes, setNodes, getEdges, fitView])

  const editNode = useCallback(() => {
    if (['block', 'question'].includes(node.type)) {
      let formName = 'editBlock' + node.id
      let title = 'Редактировать блок'
      if (node.type !== 'block') {
        formName = 'editQuestion' + node.id
        title = 'Редактировать вопрос'
      }
      formsStore.createForm(formName, {
        modalComponent: 'InputModal',
        form: {
          data: {
            value: node.data.label,
          },
        },
        props: {
          title,
          label: 'Наименование',
          placeholder: 'Введите наименование *',
          required: true,
          onSuccess: label => setNodes((nodes) => (
            nodes.map(n => {
              if (n.id === node.id) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    label,
                  },
                }
              }
              return n
            })
          )),
        },
      })
    }
  }, [
    node,
    formsStore,
    setNodes,
  ])

  const addNode = useCallback(() => {
    const savedPosition = { ...position }
    if (!node || node.type === 'choice' || node.type === 'block') {
      const formName = 'createQuestion'
      formsStore.createForm(formName, {
        modalComponent: 'TreeQuestionEditModal',
        form: {
          data: {
            choices: [],
          },
        },
        props: {
          onSuccess: q => addQuestion(q, savedPosition),
        },
      })
    }
  }, [
    position,
    node,
    formsStore,
    addQuestion,
  ])

  const removeNode = useCallback(() => {
    if (node.type === 'block') {
      onRemoveBlock()
    } else if (node.type === 'question') {
      const edges = getEdges()
      const choices = edges.filter(e => e.source === node.id).map(e => e.target)
      const allNodes = [node.id, ...choices]
      setNodes(
        (nodes) => nodes.filter((n) => !allNodes.includes(n.id)),
      )
      setEdges(
        (edges) => edges.filter((e) => !allNodes.includes(e.source) && !allNodes.includes(e.target)),
      )
    } else {
      setNodes(
        (nodes) => nodes.filter((n) => n.id !== node.id),
      )
      setEdges(
        (edges) => edges.filter((e) => e.source !== node.id && e.target !== node.id),
      )
    }
  }, [node?.type, node?.id, onRemoveBlock, getEdges, setNodes, setEdges])

  const removeEdge = useCallback(() => {
    setNodes(
      nodes => nodes.map(n => {
        if ([edge.source, edge.target].includes(n.id)) {
          return { ...n, data: { ...n.data, lastUpdate: new Date() } }
        }
        return n
      }),
    )
    setEdges(
      (edges) => edges.filter((e) => e.id !== edge.id),
    )
  }, [edge, setNodes, setEdges])

  const display = (top !== undefined || bottom !== undefined) ? undefined : 'none'

  const edgeSource = edge ? getNode(edge.source) : undefined
  const edgeSourceType = edgeSource?.type
  const isEdgeCanBeRemover = edgeSourceType === 'choice' || edgeSourceType === 'block'

  const edges = getEdges()
  const hasNextNode = node ? edges.some(e => e.source === node.id) : undefined

  return (
    <div
      style={{ top, left, bottom, right, display }}
      className={styles.contextMenu}
      {...props}
    >
      {(!node || (['block', 'choice'].includes(node.type) && !hasNextNode)) && (
        <Button
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          onClick={addNode}
        >
          + Добавить вопрос
        </Button>
      )}
      {(!node?.type && !edge) && (
        <Button
          type={BUTTON_TYPES.border}
          onClick={normalize}
        >
          Упорядочить
        </Button>
      )}
      {(!!node && node?.type && ['block', 'question'].includes(node.type)) && (
        <Button
          type={BUTTON_TYPES.dashed}
          color={BUTTON_COLORS.blue}
          onClick={editNode}
        >
          Редактировать {NODE_DICT[node.type]}
        </Button>
      )}
      {!!node && (
        <Button
          type={BUTTON_TYPES.border}
          color={BUTTON_COLORS.red}
          onClick={removeNode}
        >
          Удалить {NODE_DICT[node.type]}
        </Button>
      )}
      {!!isEdgeCanBeRemover && (
        <Button
          type={BUTTON_TYPES.border}
          color={BUTTON_COLORS.red}
          onClick={removeEdge}
        >
          Удалить связь
        </Button>
      )}
    </div>
  )
}

export default FlowContextMenu
