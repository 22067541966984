import isEqual from 'lodash/isEqual'

export const checkNodeLinks = (blocks) => {
  const unlinkedBlock = blocks.findIndex(b => !b.next_id)
  if (unlinkedBlock > -1) {
    return unlinkedBlock
  }
  for (let i = 0; i < blocks.length; i++) {
    const b = blocks[i]
    const links = [b.next_id]
    const node = []
    Object.values(b.questionary).forEach(q => {
      node.push(q.id)
      if (q.next_id) links.push(q.next_id)
      if (q.choices) links.push(...q.choices)
    })

    const hasUnlinkedNode = node.some(n => !links.includes(n))
    if (hasUnlinkedNode) {
      return i
    }
  }
  return undefined
}

export const getQuestionaryStatus = (published, archived) => {
  if (archived) return 'archive'
  if (published) return 'publish'
  return 'draft'
}

const joinArray = (arr1 = [], arr2 = []) => {
  const newArr = []
  arr1.forEach(item1 => {
    const a1 = Array.isArray(item1) ? item1 : [item1]
    arr2.forEach(item2 => {
      const a2 = Array.isArray(item2) ? item2 : [item2]
      newArr.push([...a1, ...a2])
    })
  })
  return newArr
}

export const getResults = (blocks, results) => {
  const blockChoices = blocks.map(b => (
    Object.values(b.questionary)
      .filter(q => q.type === 'choice' && !q.next_id)
      .map(a => a.id)
  ))
  let choices = blockChoices[blockChoices.length - 1].map(c => [c])
  for (let i = blockChoices.length - 2; i >= 0; i--) {
    choices = joinArray(blockChoices[i], choices)
  }
  return choices.map(a => {
    const prevResult = results.find(r => isEqual(a, r.choices))
    if (prevResult) return prevResult
    return ({
      type: 'result',
      choices: a,
    })
  })
}

export const checkResults = (results = []) => {
  return !!results.length && !results.some(r => !r.name)
}

const getQuestionary = (qId, questionsDict, parent_id) => {
  if (!qId) return {}
  const question = questionsDict[qId]
  let questionary = {
    [qId]: {
      parent_id,
      ...question,
    },
  }

  question.choices?.forEach(c => {
    const choice = questionsDict[c]
    let nextQuestion = {}
    if (choice?.next_id) {
      nextQuestion = getQuestionary(choice.next_id, questionsDict, c)
    }

    questionary = {
      ...questionary,
      [c]: {
        parent_id: question.id,
        ...choice,
      },
      ...nextQuestion,
    }
  })

  return questionary
}

export const transformQuestionsToBlocks = questions => {
  const questionsDict = questions.reduce((memo, q) => ({
    ...memo,
    [q.id]: q,
  }), {})

  return questions
    .filter(q => q.type === 'block')
    .sort((a, b) => a.order - b.order)
    .map(b => {
      const questionary = getQuestionary(b.next_id, questionsDict)
      return {
        ...b,
        questionary,
      }
    })
}
