/* eslint-disable max-len */
import React from 'react'

const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={25}
    viewBox="0 0 20 25"
    fill="none"
    {...props}
  >
    <path d="M4.6004 21.2314L8.79978 18.7209C8.92072 18.6486 9.02501 18.5516 9.10582 18.4362L18.3339 5.25716C18.6506 4.80475 18.5407 4.1812 18.0883 3.86443L14.8117 1.57012C14.3593 1.25334 13.7357 1.36329 13.4189 1.8157L4.19091 14.9947C4.11009 15.1101 4.05458 15.2413 4.02801 15.3797L3.10522 20.1844C2.94231 21.0327 3.85902 21.6746 4.6004 21.2314Z" fill="none" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M12.0879 5.28711L16.1837 8.15499" fill="none" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

export default Edit
