import classNames from 'classnames'
// import { useContext } from 'react'

// import { StoreContext } from '@/store'

import Header from '@/components/Header'
import Icon, { ICONS_TYPES, LABEL_POSITION_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const BigHeader = ({ className }) => {
  // const store = useContext(StoreContext)

  // const { formsStore } = store

  const listItems = [
    {
      iconType: ICONS_TYPES.prohibition,
      title: 'разрешена или запрещена ваша сделка (операция)',
    },
    {
      iconType: ICONS_TYPES.restrictions,
      title: 'какие ограничения применимы к вашей сделке (операции)',
    },
    {
      iconType: ICONS_TYPES.person,
      title: 'нужно ли согласовывать вашу сделку (операцию) с регулирующим органом',
    },
    {
      iconType: ICONS_TYPES.norms,
      title: 'какими нормами регулируется КАЖДАЯ сделка (операция)',
    },
  ]

  return (
    <div className={classNames(
      styles.root,
      className
    )}>
      <Header />
      <div className="container">
        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.infoTitle}>Добро пожаловать <br /> в ККМП.Контрсанкции!</div>
            <div className={styles.infoText}>
                  Это сервис, который поможет вам определить контрсанкционное
                  регулирование, применимое к вашей сделке (операции)
            </div>
            {/* <Icon
              size={30}
              className={styles.infoIcon}
              label={'Как работает наш сервис'}
              position={LABEL_POSITION_TYPES.left}
              type={ICONS_TYPES.playMini}
              onClick={() => formsStore.createForm('video', {
                modalComponent: 'AlertModal',
                props: {
                  text: <div className={styles.alertText}>Coming soon</div>,
                },
              })}
            /> */}
          </div>
          <div className={styles.listYouKnow}>
            <div className={styles.listTitle}>Вы узнаете</div>
            <div className={styles.list}>
              {listItems.map((item) => (
                <div className={styles.listItem} key={item.title}>
                  <Icon
                    size={27}
                    className={styles.listIcon}
                    type={item.iconType}
                    label={item.title}
                    position={LABEL_POSITION_TYPES.left}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BigHeader
