import { useState, useCallback } from 'react'
import classNames from 'classnames'

import Icon, { ICONS_TYPES, ROTATE_TYPES } from '@/components/Icon'

import styles from '../index.module.css'


const Question = ({ block, questionId, level = 1 }) => {
  const [openNext, setOpenNext] = useState()

  const question = block.questionary[questionId]

  const toggleOpenNext = useCallback(next => {
    if (next === openNext) {
      setOpenNext(undefined)
    } else {
      setOpenNext(next)
    }
  }, [openNext])

  return (
    <>
      <div className={styles.question} style={{ paddingLeft: 40 * level }}>
        {question.choices?.map(cId => {
          const choice = block.questionary[cId]
          const hasNext = !!choice.next_id
          return (
            <div
              key={cId}
              className={classNames(
                styles.choice,
                choice.next_id && openNext === choice.next_id && styles.open,
                !hasNext && styles.inactive,
              )}
              onClick={() => toggleOpenNext(choice.next_id)}
            >
              <span>{choice.text}</span>
              {hasNext && (
                <Icon
                  className={styles.arrow}
                  rotate={openNext === choice.next_id ? ROTATE_TYPES.down : ROTATE_TYPES.up}
                  type={ICONS_TYPES.arrowDown}
                  size={14}
                />
              )}
            </div>
          )
        })}
      </div>
      {!!openNext && (
        <Question block={block} questionId={openNext} level={level + 1} />
      )}
    </>
  )
}

export default Question
