import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const FlyingLinks = ({ className }) => {
  return (
    <div className={classNames(
      styles.root,
      className
    )}>
      <Link className={styles.link} to={'https://t.me/kkmpconnect'} target={'_blank'}>
        <Icon
          className={styles.icon}
          type={ICONS_TYPES.telegram}
        />
        <span>KKMP.CONNECT</span>
        <Icon
          className={styles.icon}
          type={ICONS_TYPES.arrowRight}
        />
      </Link>
      <Link className={styles.link} to={'mailto://Moscow@kkmp.legal'}>
        <Icon
          className={styles.icon}
          type={ICONS_TYPES.sendEmail}
        />
        <span>Связаться с нами</span>
        <Icon
          className={styles.icon}
          type={ICONS_TYPES.arrowRight}
        />
      </Link>
    </div>
  )
}

export default FlyingLinks
